import React from "react";
import Header from "../../../Dashboard/Header";
import PoweredByLeadSquared from "../../../PoweredByLeadSquared";
import {
  generateSampleCSVData
} from "../../logic";
import { Link } from "react-router-dom";
import backIcon from "../../../../images/back-image.png";
const Instructions = (props) => {
  return (
    <>
      <Header />
      <div className="container-fluid instruction-page-container">
        <div className="row instruction-page-wrapper">
          <div className="col-lg-12 instruction-container">
            <div className="row-heading">
              <div className="row-heading-one">
              <i class="fas fa-info-circle fa-2x"></i>
              <span>Instructions for CSV format</span>
              </div>
              <div className="row-header-navigation">
              <Link className="header-back-button" to={"/importLead"}>
                          <img src={backIcon} />
                          Back
                        </Link>
              </div>
            </div>
            <hr className="instruction-hr"></hr>
            <div className="instruction">
              <span>1. Format of the columns in CSV file</span>
              <span>
                The format of your CSV is important. Please arrange your CSV
                file to match the column headers shown below and then save the
                file as CSV.
              </span>
              <span onClick={generateSampleCSVData}>Click here to Download Sample CSV file</span>
              <div className="instruction-table">
                <table>
                  <tr>
                    <th>FirstName</th>
                    <th>LastName</th>
                    <th>Phone</th>
                    <th>EmailAddress</th>
                    <th>mx_Season</th>
                    <th>mx_course_campus_name</th>
                    <th>mx_course_name</th>
                    <th>mx_course_campus_college_name</th>
                    
                  </tr>
                  <tr>
                    <td>PS1</td>
                    <td>Sample1</td>
                    <td>9766620546</td>
                    <td>lsq.test2@gmail.com</td>
                    <td>2020</td>
                    <td>LPU</td>
                    <td>FullStack</td>
                    <td>LPU1</td>
                   
                  </tr>
                </table>
              </div>
            </div>
            <hr className="instruction-hr"></hr>
            <div className="instruction-one-line">
              <span>2. Maximum 1000 rows are allowed.</span>
            </div>
            <hr className="instruction-hr"></hr>
            <div className="instruction-one-line">
              <span>3. Maximum file size allowed is 1024 KB.</span>
            </div>
            {/* <hr className="instruction-hr"></hr> */}
            {/* <div className="instruction-four">
              <span>
                4. Enter multiple interested courses with comma separated
                values.
              </span>
              <span>
                If a student is interested in multiple courses, you can add them one after other seperated by a semicolon, as shown below.
              </span>
              <div className="instruction-table">
              <table>
                <tr>
                  <th>FirstName</th>
                  <th>LastName</th>
                  <th>Phone</th>
                  <th>EmailAddress</th>
                  <th>mx_Season</th>
                  <th>mx_course_campus_name</th>
                  <th>mx_course_name</th>
                  <th>mx_course_campus_college_name</th>
                  <th>mx_BD_Name</th>
                </tr>
                <tr>
                  <td>PS1</td>
                  <td>Sample1</td>
                  <td>9766620546</td>
                  <td>lsq.test2@gmail.com</td>
                  <td>2020</td>
                  <td>CSE</td>
                  <td>FullStack; Self-paced course</td>
                  <td>LPU1</td>
                  <td>James</td>
                </tr>
              </table>
              </div>
            </div>
            <div className="instruction-five"> 
              <span>Below are the list of courses we currently accept.</span>
              <div className="row">
                <div className="col-lg-12"></div>
              </div>
              <div className="course-container">
                <div className="course-list-one">
                  <div className="course-one">
                    <svg
                      stroke="#008000"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="success-class"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span>Full Stack Development</span>
                  </div>
                  
                  
                  <div className="course-one">
                    {" "}
                    <svg
                      stroke="#008000"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="success-class"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span>Java Script</span>
                  </div>
                </div>
                <div className="course-list-two">
                  
                  
                 
                  <div className="course-one">
                    {" "}
                    <svg
                      stroke="#008000"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="success-class"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span>JAVA</span>
                  </div>
                  <div className="course-one">
                    {" "}
                    <svg
                      stroke="#008000"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="success-class"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span>Self-Placed Course</span>
                  </div>
                </div>
              
           
              <div className="course-list-one">
                  <div className="course-one">
                    {" "}
                    <svg
                      stroke="#008000"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="success-class"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span>Python</span>
                  </div>
                  
                </div>

                      
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      <div className="instructions-footer">
          <PoweredByLeadSquared />
      </div>
    </>
  );
};

export default Instructions;

import { useState, useRef } from "react";
import { ACCOUNT_SETTINGS } from "./Settings/Import_Leads_Settings";
import utility from "../api-caller/utility";
import Papa from 'papaparse';

// Custom HOOK for STATE MANAGEMENT
let useStateManagement = () => {
 let chooseFileRef = useRef();
 let [totalLeads, setTotalLeads] = useState(0);
 let [uploadedFileDetails, setUploadedFileDetails] = useState({});
 let [lastUploadFileDetails, setLastUploadedFileDetails] = useState({});
 let [uploadButtonText, setUploadButtonText] = useState("Upload");
 let [fileLogs, setFileLogs] = useState([]);
 let [processLeads, setProcessLeads] = useState([])
 let [repetitiveCount, setRepetitveCount] = useState(1);
 let [logRowID, setLogRowID] = useState("uniqueId_1");
 let [displayExecutingRow, setDisplayExecutingRow] = useState(false);
 let [displayModalBox, setDisplayModalBox] = useState(false);
 let [modalContent, setModalContent] = useState({});

 let STATE_VALUES = {
  chooseFileRef, totalLeads, uploadedFileDetails, lastUploadFileDetails, uploadButtonText, fileLogs, processLeads, repetitiveCount, logRowID, displayExecutingRow, displayModalBox, modalContent
 }

 let CHANGE_STATE_VALUES = {
  setTotalLeads,
  setUploadedFileDetails,
  setLastUploadedFileDetails,
  setUploadButtonText,
  setFileLogs,
  setProcessLeads,
  setRepetitveCount,
  setLogRowID,
  setDisplayExecutingRow,
  setDisplayModalBox,
  setModalContent
 }

 return [STATE_VALUES, CHANGE_STATE_VALUES];
}

// READING CONTENT OF UPLOADED CSV FILE
let readFileData = (fileUpload) => {

 let data = new Promise((resolve, reject) => {
  // For Checking Format of Uploaded File

  var regex = /^([a-zA-Z0-9()@\s_\\.\-:])+(.csv)$/;
  if (regex.test(fileUpload.value.toLowerCase())) {
   if (typeof FileReader != "undefined") {
    var reader = new FileReader();
    reader.onload = function (e) {
     // Reading File
   //   var rows = e.target.result.split("\n");
   let parseData = Papa.parse(e.target.result, {header: false, skipEmptyLines: true});
     resolve(parseData.data);
     // console.log("ROWS ==>", rows);
   //   resolve(rows);
    }
    reader.readAsText(fileUpload.files[0]);
   }
  }
  else {
   resolve("Invalid File");
  }
 })
 return data;
}

// REMOVING UPLOADED FILE
let removeUploadedFile = (CHANGE_STATE_VALUES) => {
 let { setUploadedFileDetails } = CHANGE_STATE_VALUES;

 // Accessing Form Element By ID
 let fileUploadForm = document.getElementById("f1");

 // Clearing Form Inputs
 fileUploadForm.reset();

 // Clearing Invalid File Details from State
 setUploadedFileDetails({});
}

// FETCHING FILE DETAILS
let fetchFileDetails = (STATE_VALUES, CHANGE_STATE_VALUES) => {
 let { setUploadedFileDetails } = CHANGE_STATE_VALUES;
 let { chooseFileRef } = STATE_VALUES;

 // Accessing <input type="file"> element
 let fileUpload = chooseFileRef.current;

 if (fileUpload.value) {
  // Extracting Uploaded File Name
  let fileName = (fileUpload.value).split("\\").reverse()[0];

  // Calculating File Size in KB
  let fileSize = String((fileUpload.files[0].size / 1024).toFixed(2)) + "KB";
  // console.log("File Size", fileSize);

  // Updating File Details State
  setUploadedFileDetails({ fileName, fileSize });
 }
 else {
  // Setting File Details Empty if FILE FOUND
  setUploadedFileDetails({});
 }
}

// VALIDATING FILE  
let validateFile = (rows, fileSize) => {

 let errorMessage = "";
 let leadsArray = [];

 // Checking MAX Allowed File Size
 if (parseInt(fileSize) > ACCOUNT_SETTINGS.MAX_SIZE_ALLOWED) {
  errorMessage = {
   headerContent: "File Size Exceeded",
   bodyContent: `Max File Size Allowed : ${ACCOUNT_SETTINGS.MAX_SIZE_ALLOWED} KB`
  }
 }
 // If File Size is VALID, then
 else {

  // Converting Single Lead String Data into Array
  rows.map((x) => {
   // let y = x.split(",");
   if(x.join('') !== ""){
      let y = x.join(',').replace(/["\n\r]/g, '').split(",");
      leadsArray.push(y);
   }
  });

  // Checking whether CSV File contains rows or not
  if (leadsArray.length < 2) {
   errorMessage = {
    headerContent: "Empty File",
    bodyContent: "Uploaded CSV File is Empty"
   }
  }
  // If CSV File Contains ROWS
  else {
   // Checking Maximum Rows Allowed
   if (leadsArray.length >
    (ACCOUNT_SETTINGS.MAX_ROWS_ALLOWED + 2)) {
    errorMessage = {
     headerContent: "Rows Limit Exceeded",
     bodyContent: `Max Rows Allowed : ${ACCOUNT_SETTINGS.MAX_ROWS_ALLOWED+1}`
    }
   }
  }
 }
 return [leadsArray, errorMessage];
}

// PROCESSING LEAD DATA & CONVERTING IN PAYLOAD FORMAT
let convertInto_PayloadFormat = (leadsArray) => {

 // Empty Fields Count
 let emptyCount = 0;

 // SchemaName Array
 let schemaNameArray = leadsArray[0];

 // Adding Schema From ACCOUNT SETTINGS to SchemaName Array
 let schemaFromSettings = Object.keys(ACCOUNT_SETTINGS.ADD_COLUMNS);
 if (schemaFromSettings.length > 0) {
  schemaFromSettings.map(schemaName => {
   schemaNameArray.push(schemaName);
  })
 }

 // Trimming Schema Name (May include \r \n at end)
 schemaNameArray = schemaNameArray.map(schemaName => {
  return schemaName.trim();
 })


 let mandatoryColumns = ACCOUNT_SETTINGS.MANDATORY_COLUMN_NAMES;


 let foundMandatoryColumns = true;
 if (mandatoryColumns.length > 0) {
  foundMandatoryColumns = mandatoryColumns.every((columnName) => {
   return schemaNameArray.includes(columnName)
  })
 }

 // console.log(foundMandatoryColumns);
 // console.log("Mandatory Columns Array", mandatoryColumns);
 // console.log("Schema Array", schemaNameArray);
 // console.log("Schema from Settings", schemaFromSettings);

 // Checking Empty Values in Schema Array
 schemaNameArray.map(value => {
  if (value.trim() === "") {
   emptyCount += 1;
  }
 });

 // Removed Schema Name Row
 leadsArray.shift();

 // Removing Empty Last Row
 let lastRow = leadsArray[leadsArray.length - 1];
 if (lastRow.length <= 1) {
  leadsArray.pop();
 }

 let payload = [];

 // console.log("TRUE Leads Array ==> ", leadsArray);

 // Accessing Schema Values from ACCOUNT SETTINGS
 let schemaValueFromSettings = Object.values(ACCOUNT_SETTINGS.ADD_COLUMNS);
 let addValueToColumn = false; //FLAG
 if (schemaValueFromSettings.length > 0) {
  addValueToColumn = true;
 }

 // Accessing Single Lead Data from Leads Array
 leadsArray.map((singleLeadData) => {
  let attributeValueArray = [];

  // Adding Schema Values to Single Lead Data
  if (addValueToColumn) {
   schemaValueFromSettings.map(schemaValue => {
    singleLeadData.push(schemaValue);
   })
  }

  // Accessing each value from Lead Data of a Single lead
  singleLeadData.map((value, index) => {
   if (value !== undefined && value.trim() === "") {
    if (ACCOUNT_SETTINGS.MARK_ALL_COLUMNS_REQUIRED) {
     emptyCount += 1;
    }
    /* 
    else {    
       if (ACCOUNT_SETTINGS.REQUIRED_COLUMNS.includes(schemaNameArray[index])) {
        emptyCount += 1;
       }
    }
    */
   }
   // value !== undefined &&
   //Extra condition for checking leadid value
 if(index === 8 && value === undefined){
   value = utility.IsAuthSessionInfoCookiePresent
   ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
   : this.props.history.push("/");
   }

   attributeValueArray.push({
    Attribute: schemaNameArray[index],
    Value: value.trim(),
   });
  });
  payload.push(attributeValueArray);
 });
 // console.log("PAYLOAD ==> ", payload);
 // console.log("PAYLOAD LENGTH ==>", payload.length);
 // console.log("EMPTY COUNT ==>", emptyCount);

 return [payload, payload.length, emptyCount, foundMandatoryColumns];
}

// INITIATING LAPP CALL
let INTITIATE_LAPP_CALL_PROCESS = async (STATE_VALUES, CHANGE_STATE_VALUES) => {
 let {
  totalLeads,
  uploadedFileDetails,
  processLeads,
  repetitiveCount,
  logRowID,
 } = STATE_VALUES;

 let {
  setLastUploadedFileDetails,
  setUploadButtonText,
  setFileLogs,
  setProcessLeads,
  setRepetitveCount,
  setLogRowID,
  setDisplayExecutingRow,
  setDisplayModalBox,
  setModalContent } = CHANGE_STATE_VALUES;

 if (processLeads.length !== 0) {

  console.log("=======> Executing Set <========");
  setLastUploadedFileDetails(uploadedFileDetails);

  if (processLeads.length > 50) {
   setRepetitveCount(repetitiveCount + 1);
   setLogRowID((previousId) => {
    let splitPreviousId = previousId.split("_");
    let idCount = +(splitPreviousId[1]) + 1;
    let newId = `${splitPreviousId[0]}_${idCount}`;
    return newId;
   });

   let allLeads = [...processLeads];
   let payload50Leads = allLeads.slice(0, 50);
   // console.log("50 leads Payload Set", payload50Leads);

   try {

    let [setExecuted, response] = await CALL_LAPP(payload50Leads);
    // console.log([setExecuted, response]);

    let removedLeads = allLeads.splice(0, 50);
    setProcessLeads(allLeads);

    /*
    ===========================================
      CAPTURE LAPP RESPONSE FUNCTION ARGUMENTS
      
      1. setExecuted (Boolean)
         --------------------
         true (If SET is executed completely or partially)
         false (If SET is completely failed)
 
      2. response (Array or Object)
         --------------------------
         Array (If SET is executed completely or partially)
         Object (If SET is completely failed)
 
      3. repetitiveCount (Number)
         ------------------------
         Tells us how many the times the LAPP is getting called
         One LAPP Call is made for 50 Leads
 
      4. removedLeads (Array)
         --------------------
         Tells us about the leads which are processed during the current LAPP call.
 
      5. logRowID (String)
         -----------------
         It contains unique ID for file log row
      
      6. leadCount (Number)
         -----------------
         Tells us about total lead count in CSV file
 
      7. "pending" | "finished"  (String)
         -------------------------------
         It tells whether any further LAPP call will be made after current LAPP call or not.
         "pending" means Further LAPP call will be made
         "finished" means Current LAPP call was the last call.
 
    ===========================================
    */

    let singleFileLogRow = captureLappResponse(setExecuted, response, repetitiveCount, removedLeads, logRowID, totalLeads, "pending");

    setFileLogs((oldLogs) => {
     return [...oldLogs, singleFileLogRow]
    });

    // console.log("LOG ROW ID ==>", logRowID);
   }
   catch (err) {
    console.log(err);
    setRepetitveCount(repetitiveCount - 1);
    let singleFileLogRow = {
     setNumber: `Processing Set - ${repetitiveCount}`,
     setStatus: "Failed",
     setStatusMessage: "Processing Failed",
     childLogs: [],
     showChildLogs: false,
     rowId: logRowID,
    }
    setFileLogs((oldLogs) => {
     return [...oldLogs, singleFileLogRow];
    })
   }
  }
  else {
   setRepetitveCount(repetitiveCount + 1);
   setLogRowID((previousId) => {
    let splitPreviousId = previousId.split("_");
    let idCount = +(splitPreviousId[1]) + 1;
    let newId = `${splitPreviousId[0]}_${idCount}`;
    return newId;
   });
   let allLeads = [...processLeads];
   let remainingLeads = allLeads.slice(0,);
   // console.log("Remaining leads Payload Set", remainingLeads);

   try {
    let [setExecuted, response] = await CALL_LAPP(remainingLeads);
    // console.log([setExecuted, response]);

    let removedLeads = allLeads.splice(0,);
    setProcessLeads(allLeads);
    // console.log("Remaining Leads Array", allLeads);

    let singleFileLogRow = captureLappResponse(setExecuted, response, repetitiveCount, removedLeads, logRowID, totalLeads, "finished");

    setFileLogs((oldLogs) => {
     return [...oldLogs, singleFileLogRow]
    });
    setLogRowID("uniqueId_1");

    setDisplayModalBox(true);
    setModalContent({
     headerContent: "Completed",
     bodyContent: "All leads processing completed",
    });

    setUploadButtonText("Upload");
    setDisplayExecutingRow(false);
    setRepetitveCount(1);
    removeUploadedFile(CHANGE_STATE_VALUES);
   }
   catch (err) {
    console.log(err);
    // console.log("All Leads Array", allLeads);

    let singleFileLogRow = {
     setNumber: `Processing Set - ${repetitiveCount}`,
     setStatus: "Failed",
     setStatusMessage: "Processing Failed",
     childLogs: [],
     showChildLogs: false,
     rowId: logRowID,
    }
    setFileLogs((oldLogs) => {
     return [...oldLogs, singleFileLogRow];
    })
    setRepetitveCount(repetitiveCount - 1);
   }
  }
 }
}

// Calling Bulk Lead API
let CALL_LAPP = (payload) => {
 let time = new Promise((resolve, reject) => {

  fetch(ACCOUNT_SETTINGS.LAPP_SETTINGS.LAPP_API_URL,
   {
    method: ACCOUNT_SETTINGS.LAPP_SETTINGS.METHOD,
    headers: ACCOUNT_SETTINGS.LAPP_SETTINGS.HEADERS,
    body: JSON.stringify(payload),
   }
  )
   .then((response) => {
    return response.json();
   })
   .then((res) => {
    // console.log("[][][][] Response ==> ", res.message.body);
    let setExecuted;

    //  MEANS WHOLE SET EXECUTION FAILED
    if (res.message.body.Status === "Error") {
     let errorMessage = `${res.message.body.ExceptionType} <br/> ${res.message.body.ExceptionMessage}`;
     setExecuted = false;
     resolve([setExecuted, res.message.body]);
    }
    else {
     //  MEANS SET IS EXECTUED COMPLETELY OR PARTIALLY
     setExecuted = true;
     resolve([setExecuted, res.message.body]);
    }
   })
   .catch((err) => {
    reject("FAILED");
    console.log(err);
   });
 })
 return time;
}

// CAPTURING LAPP RESPONSE
let captureLappResponse = (setExecuted, response, repetitiveCount, removedLeads, logRowID, totalLeadCount, futureLappCall) => {

 // SET is executed completely or partially
 if (setExecuted === true) {

  let failedImportedLeads = [];
  let failedLeadsResponse = [];
  let successLeadsResponse = [];
  let successImportedLeads = [];

  response.map((singleLeadResponse, index) => {
   // Capturing Failed Leads
   if (!singleLeadResponse.LeadCreated) {

    // Storing Failed Lead Details
    failedImportedLeads.push(removedLeads[index]);

    // Storing Response of Failed Lead
    failedLeadsResponse.push(singleLeadResponse);
   } else {
      successImportedLeads.push(removedLeads[index]);
      successLeadsResponse.push(singleLeadResponse)
   }
  })

  let lastLeadCount;
  let startLeadCount;
  
 
//   console.log("failedImportedLeads",failedImportedLeads)
//   console.log("failedLeadsResponse",failedLeadsResponse)
//   console.log("successImportedLeads",successImportedLeads)
//   console.log("successLeadsResponse",successLeadsResponse)
  // Accessing which Lead Set is getting executed
  if (futureLappCall === "pending") {
   lastLeadCount = repetitiveCount * 50;
   startLeadCount = lastLeadCount - 49;
  }
  else {
   startLeadCount = ((repetitiveCount - 1) * 50) + 1;
   lastLeadCount = totalLeadCount;
  }

  // Concatinating success and failure leads data array
  let failedLeadsData = failedImportedLeads.length > 0 && extractFailedLeadsData(failedImportedLeads, failedLeadsResponse)
  let uploadedLeadsData = successLeadsResponse.length > 0 && extractFailedLeadsData(successImportedLeads, successLeadsResponse)
  let leadsData; 
   if(failedLeadsData && uploadedLeadsData && failedLeadsData.length > 0 && uploadedLeadsData.length > 0 ){
      leadsData = failedLeadsData.concat(uploadedLeadsData)
   } else if(failedLeadsData && failedLeadsData.length > 0 && !uploadedLeadsData) {
      leadsData = failedLeadsData
   } else if(uploadedLeadsData && uploadedLeadsData.length > 0 && !failedLeadsData){
      leadsData = uploadedLeadsData
   }
//   console.log("failedLeadsData",failedLeadsData)
//   console.log("uploadedLeadsData",uploadedLeadsData)
//   console.log("leadsData",leadsData)
  let logStatusMessage;
  let statusMessage;
  if(failedImportedLeads.length === 0 && successImportedLeads.length > 0){
   logStatusMessage = "Successfully Executed"
   statusMessage = "Completed"
  } else if(successImportedLeads.length === 0 && failedImportedLeads.length > 0){
   logStatusMessage = "Execution Failed"
   statusMessage = "Failed"
  } else  {
   logStatusMessage = "Partially Executed"
   statusMessage = "Partial"
  }

  let singlefileLogRow = {
   setNumber: `Executing Leads ( ${startLeadCount} - ${lastLeadCount} )`,
   setStatusMessage: logStatusMessage,
   setStatus: statusMessage,
   childLogs: leadsData,
   rowId: logRowID
  }

  return singlefileLogRow;
 }

 // SET execution completely failed
 else {
  let exceptionMessage;

  // Finding Set Status Message
  switch (response.ExceptionType) {
   case "MXDuplicateEntryException":
    exceptionMessage = "Duplicate entries found please check below logs";
    break;

   default:
   //  exceptionMessage = response.ExceptionType;
   exceptionMessage = "Error occured while uploading";
  }

  let lastLeadCount;
  let startLeadCount;

  // Accessing which Lead Set is getting executed
  if (futureLappCall === "pending") {
   lastLeadCount = repetitiveCount * 50;
   startLeadCount = lastLeadCount - 49;
  }
  else {
   startLeadCount = ((repetitiveCount - 1) * 50) + 1;
   lastLeadCount = totalLeadCount;
  }


  let singlefileLogRow = {
   setNumber: `Executing Leads ( ${startLeadCount} - ${lastLeadCount} )`,
   setStatus: "Failed",
   setStatusMessage: exceptionMessage,
   showChildLogs: true,
   childLogs: extractFailedLeadsData(removedLeads, []),
   rowId: logRowID
  }

  return singlefileLogRow;
 }
}

// EXTRACT FAIL LEADS DATA
let extractFailedLeadsData = (failedLeadsData, failedLeadsResponse) => {
 // console.log("failedLeadsResponse ==> ", failedLeadsResponse);
 let childLogs = [];
//  console.log("failedLeadsData",failedLeadsData)
//  console.log("failedLeadsResponse",failedLeadsResponse)
 for (let i = 0; i < failedLeadsData.length; i++) {
  let leadData = {};
  for (let j = 0; j < failedLeadsData[i].length; j++) {
   let columnName = failedLeadsData[i][j];

   let uniqueIdentifier = ACCOUNT_SETTINGS.UNIQUE_IDENTIFIER_SCHEMA_NAME;

   let attributeName = columnName.Attribute.trim();

   // let lastCharacter = attributeName[attributeName.length - 1];

   // Removing Carriage return (Equivalent to the Enter or Return key)
   // if (lastCharacter.charCodeAt(0) === 13) {
   //  attributeName = attributeName.slice(0, -1);
   // }

   if (attributeName === uniqueIdentifier) {
    leadData[uniqueIdentifier] = columnName.Value;
   }
   if (attributeName === "FirstName") {
    leadData = { ...leadData, FirstName: columnName.Value };
   }
   if (attributeName === "LastName") {
    leadData = { ...leadData, LastName: columnName.Value };
   }
   if (attributeName === "Phone") {
    leadData = { ...leadData, Phone: columnName.Value };
   }
   if (attributeName === "EmailAddress") {
    leadData = { ...leadData, EmailAddress: columnName.Value };
   }
   if (attributeName === "mx_Season") {
      leadData = { ...leadData, mx_Season: columnName.Value };
   }
   if (attributeName === "mx_course_campus_name") {
      leadData = { ...leadData, mx_course_campus_name: columnName.Value };
   }
   if (attributeName === "mx_course_name") {
      leadData = { ...leadData, mx_course_name: columnName.Value };
   }
   if (attributeName === "mx_course_campus_college_name") {
      leadData = { ...leadData, mx_course_campus_college_name: columnName.Value };
   }

   /* This case will only work when there is:
      Partial Execution of SET   */
       
   if (failedLeadsResponse.length > 0) {
    const errorMessage = failedLeadsResponse[i].ErrorMessage 
             && failedLeadsResponse[i].ErrorMessage.slice(0,9) === "Duplicate" 
             ? "Duplicate Entry" : failedLeadsResponse[i].ErrorMessage    
    leadData = {
     ...leadData,
     errorMessage: errorMessage,
     exceptionType: failedLeadsResponse[i].ExceptionType
    }
   }
  }
  childLogs.push(leadData);
 }
 return childLogs;
}

// CHILD LOGS CONTENT TOGGLER VIA VIEW LOGS BUTTON
let logsViewButtonToggler = (uniqueId) => {
 let childWrapperElement = document.getElementById(uniqueId);
 let viewLogsBtnElement = document.getElementById(`${uniqueId}_btnToggler`);
 let parentWrapperElement = document.getElementById(`${uniqueId}_parentWrapper`);


 if (childWrapperElement.style.display === "none") {
  childWrapperElement.style.display = "block";
  parentWrapperElement.classList.add("drop-shadow-for-childs");
  viewLogsBtnElement.innerHTML = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" color="#90A4AE" class="mr-2" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg" style="color: rgb(144, 164, 174);"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>Hide Log`
 }
 else {
  childWrapperElement.style.display = "none"
  parentWrapperElement.classList.remove("drop-shadow-for-childs");
  viewLogsBtnElement.innerHTML = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" color="#90A4AE" class="mr-2" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg" style="color: rgb(144, 164, 174);"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>View Log`
 }
}

let generateSampleCSVData = () => {
 let columnNamesArray = ACCOUNT_SETTINGS.SAMPLE_CSV_DATA.columnNames;
 let columnNamesString = columnNamesArray.join(",") + "\n";
 let rowData = ACCOUNT_SETTINGS.SAMPLE_CSV_DATA.rowData;

 let csvData = columnNamesString;

 rowData.forEach(function (singleRowData) {
  csvData += singleRowData.join(',');
  csvData += "\n";
 });

 var hiddenElement = document.createElement('a');
 hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
 hiddenElement.target = '_blank';

 //Setting Name for CSV File
 hiddenElement.download = ACCOUNT_SETTINGS.SAMPLE_CSV_DATA.fileName;
 hiddenElement.click();
 console.log(csvData);
}


export { useStateManagement, removeUploadedFile, fetchFileDetails, readFileData, validateFile, convertInto_PayloadFormat, CALL_LAPP, INTITIATE_LAPP_CALL_PROCESS, captureLappResponse, logsViewButtonToggler, generateSampleCSVData };
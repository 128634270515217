import React from 'react'
import { FiCheck } from "react-icons/fi";
import { IoMdAlert } from "react-icons/io";
import { ACCOUNT_SETTINGS } from "../../Settings/Import_Leads_Settings.js"
import { FaEye } from "react-icons/fa";
import iconPartial from "../../../../images/icon-partial.png";
import iconSuccess from "../../../../images/icon-success.png";

let setFontColor = (value) => {
 let colorClass = "";

 switch (value) {
  case "Completed":
   colorClass = "success-class"
   break;

  case "Failed":
   colorClass = "failure-class"
   break;

  case "Partial":
   colorClass = "partial-success-class"
   break;

  default:
   break;
 }
 return colorClass;
}

const UploadedFileLogs = ({ STATE_VALUES,
 logsViewButtonToggler }) => {

 let { fileLogs, displayExecutingRow, lastUploadFileDetails } = STATE_VALUES;
 //  console.log(fileLogs);
 const handleErrorMessage = (errorMessage, statusMessage) => {
   if(errorMessage){
     return <span style={{ color: "red"}}>{errorMessage}</span>
    } else if(!errorMessage &&  statusMessage == "Duplicate entries found please check below logs"){
     return <span style={{ color: "red"}}>{"Not Uploaded"}</span>
   } else {
    return <span style={{ color: "green"}}>{"Uploaded"}</span>
   }
 }

 return (
  <>
   {(fileLogs.length > 0 || displayExecutingRow) &&
    <div className="container">
     <div className="row">
      <div className="col-lg-12 px-sm-0 overflow-auto">

       <div className="uploaded-file-logs-section">

        <div className="single-log-row-wrapper">
         <div className="file-logs-heading-row">
          <div className="file-name-heading">
           {lastUploadFileDetails.fileName}
          </div>
          <div className="file-size-heading">
           File Size : {lastUploadFileDetails.fileSize}
          </div>
         </div>
        </div>

        {fileLogs.map((singleRowData) => {
         return (
          <React.Fragment
           key={`${singleRowData.rowId}_parentKey`}>

           {/* SINGLE ROW WRAPPER */}
           <div className="single-log-row-wrapper">

            {/* PARENT ROW */}
            <div className={`parent-log-row`}
             id={`${singleRowData.rowId}_parentWrapper`}>

             <div className="set-number">
              {singleRowData.setNumber}
             </div>
             
             <div>
               
              <div className={`set-status 
              ${setFontColor(singleRowData.setStatus)}`}>
                {singleRowData.setStatus && 
                  singleRowData.setStatus === "Partial" &&
                  <img src={iconPartial}/>}
                  {singleRowData.setStatus && 
                  singleRowData.setStatus === "Completed" &&
                  <img src={iconSuccess}/>}
                {singleRowData.setStatusMessage}
              </div>
             </div>

             <div className="set-logs">
              {/* {singleRowData.setStatus === "Completed" &&
               <svg stroke="#008000" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" class="success-class" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"></polyline></svg>} */}

              {singleRowData &&
               <button className="view-logs-btn"
                id={`${singleRowData.rowId}_btnToggler`}
                onClick={() => {
                 logsViewButtonToggler(singleRowData.rowId);
                }}>
                <>
                 <FaEye size="18px" color="#90A4AE"
                  className="mr-2 failure-class" />
                  View Log
                </>
               </button>
              }
             </div>
            </div>

            {/* CHILD ROWS WRAPPER */}
            <div className="child-logs-wrapper"
             id={`${singleRowData.rowId}`}
             style={{ display: "none" }}>
                  <div className="upload-logs-table">
              <table>
                <tr>
                  <th>Status</th>
                  <th>FirstName</th>
                  <th>LastName</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Season</th>
                  <th>Campus Name</th>
                  <th>Course Interested</th>
                  <th>College Name</th>
                </tr>
                {singleRowData &&
              singleRowData.childLogs.length > 0 &&
              singleRowData.childLogs.map(
               (singleChildLogRow, index) => {
                return (<tr>
                <td>{handleErrorMessage(singleChildLogRow.errorMessage, singleRowData.setStatusMessage)}</td>
                <td>{singleChildLogRow['FirstName']}</td>
                <td>{singleChildLogRow['LastName']}</td>
                <td>{singleChildLogRow['Phone']}</td>
                <td>{singleChildLogRow['EmailAddress']}</td>
                <td>{singleChildLogRow['mx_Season']}</td>
                <td>{singleChildLogRow['mx_course_campus_name']}</td>
                <td>{singleChildLogRow['mx_course_name']}</td>
                <td>{singleChildLogRow['mx_course_campus_college_name']}</td>
              </tr>)
               })}
                
              </table>
            </div>
             {/* {singleRowData &&
              singleRowData.childLogs.length > 0 &&
              singleRowData.childLogs.map(
               (singleChildLogRow, index) => {
                return (<React.Fragment
                 key={index}>
                 <div className="child-log-row">
                  <div className="lead-information">
                   <IoMdAlert className="failure-class" size="20px" color="red" />
                   <svg stroke="#f85858" fill="#f85858" strokeWidth="0" viewBox="0 0 512 512" className="failure-class" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.6 48 48 141.601 48 256s93.6 208 208 208 208-93.601 208-208S370.4 48 256 48zm24 312h-48v-40h48v40zm0-88h-48V144h48v128z"></path></svg>
                   <span>{singleChildLogRow[ACCOUNT_SETTINGS.UNIQUE_IDENTIFIER_SCHEMA_NAME]}</span>
                   <span>{`${singleChildLogRow['FirstName']} ${singleChildLogRow['LastName']}`}</span>
                   <span>{singleChildLogRow['Phone']}</span>
                  </div>
                  <div className="error-information">
                   {singleChildLogRow.errorMessage ? singleChildLogRow.errorMessage : "Success"}
                  </div>
                 </div>
                </React.Fragment>)
               })
             } */}
            </div>
           </div>
          </React.Fragment>
         )
        })}

        {/* Showing Execution Row */}
        {displayExecutingRow &&
         <div className="single-log-row-wrapper">
          <div className="parent-log-row">
           <div className="set-number">
            Executing...
          </div>
          </div>
         </div>}
       </div>
      </div>
     </div>
    </div>
   }
  </>
 )
}

export default UploadedFileLogs;

import React from "react";
import SessionChecker from "../sesionChecker";
import "../../css/LandingPage.css";
import "bootstrap/dist/css/bootstrap.css";
import ResetForm from "./ResetForm";
import "../../css/Form.css";
import AOS from "aos";
import "aos/dist/aos.css";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    AOS.init();
  }
  render() {
    const alt = "Refferal portal";
    return (
      <div>
        <SessionChecker />

        <ResetForm />
      </div>
    );
  }
}
export default ResetPassword;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Register,
  Login,
  ForgotPasswordAPI,
  UserData,
} from "../api-caller/apiCaller";
import { withRouter } from "react-router-dom";
import utilityFunction from "../api-caller/utility";

import { API_Login } from "../api-caller/signing-request";
import GetLoginVaidationSchema from "../validations/validation";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: "",
      isSubmitting: false,
    };
    this.changeState = this.changeState.bind(this);
  }
  changeState(stateInfo) {
    this.setState(stateInfo);
    this.setState({ isSubmitting: false });
  }
  reset = () => {};
  render() {
    console.log(this.props.changeState);
    const that = this;
    return (
      <Formik
        initialValues={{ phone: "", password: "" }}
        onSubmit={(values, formikActions) => {
          //   this.setState({ isSubmitting: true });
          console.log("phone", values.phone);
          API_Login({
            Password: values.password,

            SearchKeyData: {
              SchemaName: "Phone",

              Value: values.phone,
            },
          })
            .then(function (response) {
              console.log("response=", response);
              localStorage.clear();
              console.log("data", response.Status);
              if (response.Status && response.Status === "Error") {
                let errorMessage = response.ExceptionMessage;
                if (
                  response.ExceptionMessage ===
                  "Either username or password is incorrect."
                )
                  errorMessage = "Either phone or password is incorrect.";
                console.log("error=>", errorMessage);
                that.setState({ formError: errorMessage });
                // this.setState({ isSubmitting: false });

                // alert(errorMessage);
                // console.log("p=");
                // alert(errorMessage);
                // window.location.reload(true);
                // this.reset();
              } else if (response.LeadId) {
                localStorage.setItem("username", response);
                const authSessionInfo = {
                  isAuthenticated: true,
                  leadId: response.LeadId,
                  authKey: response.AuthKey,
                };
                console.log("authSessionInfo ", authSessionInfo);
                utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);
                that.props.history.push("/Dashboard");
              }
            })
            .catch(function (error) {
              console.log("err=", error);
            });
          formikActions.setSubmitting(false);
        }}

        // validationSchema={GetLoginVaidationSchema}
      >
        <Form className="form-container" data-aos="zoom-in">
          <span style={{ color: "red" }}>
            {this.state.formError ? this.state.formError : null}
          </span>
          <div className="form-group ">
            <label htmlFor="phone">
              Phone
              <sup>
                <span className="star-color pl-lg-1">.</span>
              </sup>
            </label>
            <Field
              type="text"
              placeholder="Phone"
              autoComplete="false"
              name="phone"
            ></Field>
            {/* <div className="errorbox">
              <ErrorMessage name="phone" className="errorDiv" />
            </div> */}
          </div>
          <div className="form-group">
            <span>
              <label
                htmlFor="password "
                style={{
                  color: " #454F5B",
                  fontWeight: "bold",
                }}
              >
                Password
                <sup>
                  <span className="star-color pl-lg-1">.</span>
                </sup>
              </label>
              <a
                className="float-right "
                href="#"
                onClick={() => this.props.changeState({ tab: 2 })}
              >
                Forgot password?
              </a>
            </span>

            <Field
              autoComplete="new-password"
              type="password"
              placeholder="Password"
              name="password"
            ></Field>
            <div className="errorbox">
              <ErrorMessage name="password" className="errorDiv" />
            </div>
          </div>
          {/* <label className='mb-4'>
            <input type='checkbox' name='remember' /> Remember me
          </label> */}

          <div className="mt-4">
            <button
              type="submit"
              disabled={this.state.isSubmitting}
              className="fbtn btn-primary btn-pill"
            >
              {this.state.isSubmitting ? "Loading..." : "Login"}
            </button>
          </div>
          <div className="text-center w-100 no-account">
            Don’t have an Account?
            <span
              style={{
                paddingLeft: "5px",
                color: "#007bff",
                cursor: "pointer",
              }}
              className="blue-text"
              onClick={() => {
                this.props.changeState({ tab: 0 });
              }}
            >
              Sign Up
            </span>
          </div>
        </Form>
      </Formik>
    );
  }
}

export default withRouter(LoginForm);

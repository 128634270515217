import React from "react";
import { Link } from "react-router-dom";
import SessionChecker from "../sesionChecker";
import "../../css/LandingPage.css";

import logo from "../../images/referral-logo.svg";

import Form from "../Forms/Forms";
import "../../css/Form.css";
import AOS from "aos";
import img1 from "../../images/img2.svg";
import img2 from "../../images/img3.svg";
import agent from "../../images/agents.svg";
import buyer from "../../images/buyer.svg";
import builder from "../../images/builder.svg";
import signup from "../../images/signup.svg";
import addreferal from "../../images/add-referral.svg";
import watch from "../../images/watch.svg";
import transparency from "../../images/transpirancy.svg";
import dashboard from "../../images/dashboard.svg";
import status from "../../images/status update.svg";
import tick from "../../images/tick.svg";
import leadsquared from "../../images/leadsquared.svg";
import "./landingPage.css";
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }
  render() {
    const alt = "Refferal portal";
    return (
      <div style={{ backgroundColor: "#FAFCFF", height: "100%" }} className="landing-page-wrapper">
        <SessionChecker />

        <Form />
        {/* <button>
          <Link to={"/importLead"}> Import Lead</Link>
        </button> */}
      </div>
    );
  }
}
export default LandingPage;

import React from "react";
import Header from "./Header";
import "../../css/dashboard.css";
import img3 from "../../images/AddLead.png";
import RenderForm from "../Forms/LeadForm";
import { Link } from "react-router-dom";

import DataTable from "./DataTableNew";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import PoweredByLeadSquared from "../PoweredByLeadSquared/index"
var alt = "ReferralPortal";

class Dashboard extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <ReferralHistory />
      </div>
    );
  }
}
export default Dashboard;

class ReferralHistory extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
    this.state = {
      rowCount: 1,
    };
    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.changeState = this.changeState.bind(this);
    this.overlay = React.createRef();
    this.form = React.createRef();
  }
  showForm = () => {
    this.overlay.current.style.display = "block";
    this.form.current.style.display = "block";
    this.form.current.classList.add("show");
    $("body").css("overflow-y", "hidden");
  };
  hideForm = () => {
    this.overlay.current.style.display = "none";
    this.form.current.style.display = "none";
    $("body").css("overflow-y", "scroll");
    // this.form.current.classList.remove('show');
    window.location.reload(true);
  };
  changeState(stateInfo) {
    this.setState(stateInfo);
  }
  render() {
    console.log("row count=", this.state.rowCount);
    return (
      <>
      <div className="refferalsTable d-flex ">
        <div ref={this.overlay} className="overlay dec p-2"></div>
        <div
          className="add-lead-form add-new-lead-popup "
          data-aos="slide-left"
          ref={this.form}
          // style={{ backgroundColor: "black" }}
        >
          <RenderForm props={{ hideForm: this.hideForm }} />
        </div>
        {this.state.rowCount !== 0 ? (
          <DataTable
            props={{ changeState: this.changeState, showForm: this.showForm }}
          />
        ) : (
          <div className="container d-flex justify-content-center my-auto">
            <div className="text-center ">
              <img
                src={img3}
                className="d-block m-auto "
                style={{ height: "100px" }}
                alt={alt}
              ></img>
              <div className="no-ref">No Lead Added</div>
              <div className="new-lead">
                <div
                  className="midsize-btn mb-0 md-0 mt-3 mt-md-0 mr-3"
                  onClick={this.showForm}
                >
                  {/* <Link style={{ color: "white" }} to="/Form"> */}
                  Add New Lead
                  {/* </Link> */}
                </div>
                <div
                  className="btn import-btn mb-0 md-0 mt-3 mt-md-0 mr-3"
                  style={{
                    border: "1px solid #0b7bff",
                    color: "#0b7bff",
                    margin: "0px !important",
                    background: "#FFFFFF 0% 0% no-repeat padding-box"
                    // marginLeft: " 60%",
                  }}
                  // onClick={() => this.props.props.showForm()}
                >
                  <a href="/importLead">Import Lead</a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
       <PoweredByLeadSquared />
       </>
    );
  }
}

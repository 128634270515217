import axios from "axios";
import AuthHandler from "./utility";
import constants from "../constants";
import config from "../config";
console.log("pop=", constants.PortalAPI);

let portalAPIInstance = axios.create({
  baseURL: constants.PortalAPI.baseURL,
  timeout: 60000,
});

portalAPIInstance.interceptors.request.use(
  (config) => {
    if (AuthHandler.IsAuthSessionInfoCookiePresent()) {
      return config;
    } else {
      window.location.href = "/";
      throw new axios.Cancel("Operation Cancelled");
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

function API_Register(payload) {
  console.log("in login API ==", payload);
  alert("");
  return portalAPIInstance({
    method: "post",
    url: constants.PortalAPI.endPoints.Register,
    data: payload,
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}

function API_otpGenerate(payload) {
  // console.log(payload, "Generate Mobile Number");
  return portalAPIInstance({
    method: "post",
    url: constants.PortalAPI.endPoints.OTP_GENERATE,
    data: payload,
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}

function API_otpVerify(payload) {
  // console.log(payload, "Generated Mobile Number");
  return portalAPIInstance({
    method: "post",
    url: constants.PortalAPI.endPoints.OTP_VERIFY,
    data: {
      Otp: payload.otp,
      FieldContent: payload.phone,
      Code: payload.code,
    },
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}
export const headerUpdatePassword = {
  "Content-Type": "application/json",
  Authorization: AuthHandler.GetAuthKey(),
};

const API_Login = async (payload) => {
  console.log("in login API ==", payload, AuthHandler.GetAuthKey());
  // alert("00p9090");

  // return portalAPIInstance({
  //   method: "post",
  //   url: constants.PortalAPI.endPoints.Signin,
  // data: payload,
  // headers: { Authorization: AuthHandler.GetAuthKey() },
  // });
  //  return await axios.post(
  //    `/api/Authentication/Signin?accessKey=${ACCESS_KEY}&secretKey=${SECRET_KEY}`,
  //    loginData
  //  );
  // return await axios.post(
  //   `https://portalapi-in21.leadsquared.com/api/Authentication/Signin`,

  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: config.AuthKey,
  //     },
  //     body: JSON.stringify(payload),
  //   }
  // );
  return fetch(
    constants.PortalAPI.baseURL + constants.PortalAPI.endPoints.Signin,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: config.AuthKey,
      },
    }
  ).then((Response) => Response.json());
};

function API_RequestResetPasswordLink(payload) {
  return portalAPIInstance({
    method: "post",
    url: constants.PortalAPI.endPoints.FORGOT_PASSWORD,
    data: payload,
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}

const API_ResetPassword = (payload) => {
  return portalAPIInstance({
    method: "post",
    url: constants.PortalAPI.endPoints.RESET_PASSWORD,
    data: payload,
    headers: { Authorization: AuthHandler.GetConfiguredAuthKey() },
  });
};

const API_Logout = (payload) => {
  return portalAPIInstance({
    method: "post",
    url: constants.PortalAPI.endPoints.SIGN_OUT,
    data: payload,
    headers: { Authorization: AuthHandler.GetConfiguredAuthKey() },
  });
};

//  const API_Login1 = (body) => {​​​​​​​​
//   console.log("body login=",body);
//     return lsqApiInstance({​​​​​​​​ method: "POST",
//     headers: {​​​​​​​​
//      "x-api-key": "PJsVvYdokA2B2CL3yKHUC8CsMgLo3U72McU6F363",
//     "Content-Type": "application/json"
//  },​​​​​​​
//     ​
//     url: "https://lapps-us11.leadsquared.com/execute?name=da_36410_3b98f543&stage=Test",
//      data: body, json: true, }​​​​​​​​)}

export {
  API_Register,
  API_otpGenerate,
  API_otpVerify,
  API_Login,
  API_RequestResetPasswordLink,
  API_ResetPassword,
  API_Logout,
};

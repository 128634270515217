import Loader from "react-loader-spinner";
import React from "react";
export default class Spinner extends React.Component {
  //other logic

  render() {
    // console.log('loading ', this.props.show);
    // console.log("popopo==", this.props.show);
    // alert(this.props.show);
    if (this.props.show) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            paddingBottom: "100px",
            position: "fixed",
            height: "calc(100vh - 72px)",
            width: "100vw",
            zIndex: 100000,

            background: " #FAFCFF",
          }}
        >
          <Loader
            type="ThreeDots"
            color="#438EE5"
            width={60}
            timeout={100000000000000}
          />
        </div>
      );
    }
  }
}

import config from "../config/index";
import queryString from "query-string";

var utilityFunction = {
  GetAuthKey: function () {
    return isAuthSessionInfoCookiePresent()
      ? getAuthSessionInfoCookieValue("authSessionState").authKey
        ? getAuthSessionInfoCookieValue("authSessionState").authKey
        : config.AuthKey
      : config.AuthKey;
  },
  GetAccessKey: function () {
    return config.AccessKey;
  },
  GetSecretKey: function () {
    return config.SecretKey;
  },

  GetConfiguredAuthKey: function () {
    return config.AuthKey;
  },
  SetAuthSessionInfoCookie: function (authSessionState) {
    var now = new Date();
    var time = now.getTime();
    time += 180000 * 1000;
    now.setTime(time);
    var utmValues = queryString.parse(window.location.search);
    var utmObj = {
      utm_source_campaign: utmValues.utm_source_campaign,
      utm_source_name: utmValues.utm_source_name,
      utm_source_medium: utmValues.utm_source_medium,
      utm_source_term: utmValues.utm_source_term,
      utm_source_content: utmValues.utm_source_content,
    };
    if (JSON.stringify(utmObj) === JSON.stringify({})) {
      utmObj = getAuthSessionInfoCookieValue("utmObj");
    }
    document.cookie = `auth-session-info=${JSON.stringify({
      authSessionState: authSessionState,
      utmObj: utmObj,
    })};expires=${now.toUTCString()};path=/`;
  },
  GetAuthSessionInfoCookieValue: function (propertyName) {
    return getAuthSessionInfoCookieValue(propertyName);
  },
  ExpireAuthSessionInfoCookie: function () {
    document.cookie =
      "auth-session-info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
  },
  IsAuthSessionInfoCookiePresent: isAuthSessionInfoCookiePresent,
};

function getAuthSessionInfoCookieValue(propertyName) {
  var cookieValue = document.cookie.replace(
    /(?:(?:^|.*;\s*)auth-session-info\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  var val;
  try {
    val = JSON.parse(cookieValue)[propertyName];
  } catch (e) {
    val = {};
  }
  return val;
}

function isAuthSessionInfoCookiePresent() {
  console.log("bb==", document.cookie.indexOf("auth-session-info"));
  // alert("isAuthkehy inside");
  return document.cookie.indexOf("auth-session-info") >= 0;
}

export default utilityFunction;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Register,
  Login,
  ForgotPasswordAPI,
  UserData,
} from "../api-caller/apiCaller";
import { withRouter } from "react-router-dom";
import GetForgotVaidationSchema from "../validations/validation";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: "",
      isSubmitting: false,
      isbutton:false
    };
    this.changeState = this.changeState.bind(this);
  }
  changeState(stateInfo) {
    this.setState(stateInfo);
    this.setState({ isSubmitting: false });
  }

  validateEmail = (sEmail) => {
    if (sEmail) {
      let reEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (!sEmail.match(reEmail)) {
        return "Must be Valid";
      }
      return null;
    }
    return "Required";
  };
  validateEmailInputs = () => {
    const errors = {
      emailError: null,
    };
    const { email } = this.state;
    errors.emailError = this.validateEmail(email);
    this.setState({ errors });
  };
  render() {
    // console.log(this.props.props.changeState);
    return (
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values, formikActions) => {
          this.setState({ isSubmitting: true });
          // console.log('inside forgot submit ', values, formikActions);
          this.validateEmailInputs();
          ForgotPasswordAPI(
            {
              EmailAddress: values.email,
            },
            {
              changeState: this.changeState,
              resetForm: formikActions.resetForm,
              props: this.props.props.myprops,
             
            }
          );
        }}
        // validationSchema={GetForgotVaidationSchema}
      >
        <Form className="form-container" data-aos="zoom-in">
          <p className="form-heading">Forgot Password</p>
          <p className="form-heading-sub">
            Please enter your email, We will send a password reset link.
          </p>
          <div className="text-center">
            {this.state.formError ? this.state.formError : null}
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Email
              <sup>
                <span className="star-color pl-lg-1">.</span>
              </sup>
            </label>
            <Field
              type="email"
              placeholder="Email Addresss"
              autoComplete="false"
              name="email"
            ></Field>
            <div className="errorbox">
              <ErrorMessage name="email" className="errorDiv" />
            </div>
          </div>

          <div className="mt-4">
            <button
              type="submit"
              disabled={this.state.isButton ? "disabled" : ""}
              className={this.state.isButton ? "fbtn btn-primary btn-pill btn-inactive" : "fbtn btn-primary btn-pill btn-active"}
            >
              {this.state.isSubmitting
                ? "Loading..."
                : "Send Password Reset Link"}
            </button>
          </div>
          <div className="form-group d-flex justify-content-center mt-24 mb-0">
            <span>Already have an Account?</span>
            <a
              href="#"
              className="blue-text"
              onClick={() => this.props.props.changeState({ tab: 1 })}
            >
              Login
            </a>
          </div>
        </Form>
      </Formik>
    );
  }
}

export default withRouter(ForgotPassword);

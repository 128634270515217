import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Register,
  Login,
  ForgotPasswordAPI,
  UserData,
} from "../api-caller/apiCaller";
import RegisterForm from "./RegistrationForm";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import { withRouter } from "react-router-dom";
import leadsquared from "../../images/leadsquared.svg";
import logo from "../../images/logo.png";
import axios from "axios";

class Forms extends React.Component {
  state = {
    tab: 1,
  };
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
  }
  changeState(stateInfo) {
    //console.log(stateInfo);
    this.setState(stateInfo);
  }
  render() {
    if (this.state.tab === 1)
      return (
        <div className="form-content">
          <div className="log-web login-logo">
            <img src={logo} alt="leadsquared" />
          </div>
          <div className="bg-web">
            <div className="text-container">
              <div>
                <p className="tt">Leap Finance</p>
                <p className="bt">
                  Powered by India's best study abroad experts
                </p>
              </div>
            </div>
          </div>
          <div className="form-section">
            <div className="log-mobile">
              <img src={logo} alt="leadsquared" />
            </div>
            <div className="container-fluid">
              {/* <LoginForm
                props={{ changeState: this.changeState, myprops: this.props }}
              /> */}
              <LoginForm changeState={this.changeState} />
            </div>
            <footer className="form-footer-section footer">
              <div className="container d-flex justify-content-between">
                <span></span>
                <span>
                  Powered by
                  <img src={leadsquared} alt="leadsquared" />
                </span>
              </div>
            </footer>
          </div>
        </div>
      );
    else if (this.state.tab === 0)
      return (
        <div className="form-content">
          <div className="log-web">
            <img src={logo} alt="leadsquared" />
          </div>
          <div className="bg-web">
            <div className="text-container">
              <div>
                <p className="tt">Leap Finance</p>
                <p className=" bt">
                  Powered by India's best study abroad expert
                </p>
              </div>
            </div>
          </div>
          <div className="form-section">
            <div className="log-mobile register-logo">
              <img src={logo} alt="leadsquared" />
            </div>
            <div className="container-fluid">
              <RegisterForm
                props={{ changeState: this.changeState, myprops: this.props }}
              />
            </div>
            <footer className="form-footer-section footer">
              <div className="container d-flex justify-content-between">
                <span></span>
                <span>
                  Powered by
                  <img src={leadsquared} alt="leadsquared" />
                </span>
              </div>
            </footer>
          </div>
        </div>
      );
    else if (this.state.tab === 2)
      return (
        <div className="form-content">
          <div className="log-web">
            <img src={logo} alt="leadsquared" />
          </div>
          <div className="bg-web">
            <div className="text-container">
              <div>
                <p className="tt ">Forgot Password</p>
                <p className="bt pl-4 pr-4 pl-lg-0 pr-lg-0 ">
                  Please enter your email, We will send a password reset link.
                </p>
              </div>
            </div>
          </div>
          <div className="form-section">
            <div className="log-mobile">
              <img src={logo} alt="leadsquared" />
            </div>
            <div className="container-fluid">
              <ForgotPassword
                props={{
                  changeState: this.changeState,
                  myprops: this.props,
                }}
              />
            </div>
            <footer className="form-footer-section footer">
              <div className="container d-flex justify-content-between">
                <span></span>
                <span>
                  Powered by
                  <img src={leadsquared} alt="leadsquared" />
                </span>
              </div>
            </footer>
          </div>
        </div>
      );
  }
}
export default withRouter(Forms);

// class SignUpForm extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       formError: "",
//       isSubmitting: false,
//       userListData: [],
//     };
//     this.changeState = this.changeState.bind(this);
//   }
//   componentDidMount() {
//     let userList = [];
//     axios
//       .get(
//         `https://api-in21.leadsquared.com/v2/UserManagement.svc/Users.Get?accessKey=u$rcf27908999d7f00d879e9e925cf8d892&secretKey=69a07a9af9a12985286c5f3a3bd07c39e8fd88e6`
//       )
//       .then((res) => {
//         const data = res.data;
//         data.map((dataUser, index) => {
//           if (dataUser) {
//             const firstName = dataUser.FirstName;
//             const lastName = dataUser.LastName;
//             const fullName = firstName + " " + lastName;
//             userList.push(fullName);
//           }
//           this.setState({ userListData: userList });
//           console.log(userList);
//         });
//         console.log(data);
//       });
//   }
//   changeState(stateInfo) {
//     this.setState(stateInfo);
//     this.setState({ isSubmitting: false });
//   }
//   render() {
//     // console.log(this.state.formError);
//     return (
//       <Formik
//         initialValues={{
//           channelpartnername: "",
//           pancardno: "",
//           phonenumber: "",
//           email: "",
//           password: "",
//           address1: "",
//           address2: "",
//           city: "",
//           zip: "",
//           state: "",
//           confirmpassword: "",
//           reraid: "",
//           relationshipmanager: "",
//         }}
//         validationSchema={GetVaidationSchema}
//         onSubmit={(values, formikActions) => {
//           this.setState({ isSubmitting: true });
//           console.log("formick action ", formikActions.isSubmitting);
//           var LeadFields = [];
//           LeadFields.push({
//             Attribute: "FirstName",
//             Value: values.channelpartnername,
//           });
//           LeadFields.push({
//             Attribute: "Phone",
//             Value: values.phonenumber,
//           });
//           LeadFields.push({
//             Attribute: "EmailAddress",
//             Value: values.email,
//           });
//           LeadFields.push({
//             Attribute: "mx_PAN_Card_No",
//             Value: values.pancardno,
//           });
//           LeadFields.push({
//             Attribute: "mx_CP_Code",
//             Value: values.reraid,
//           });
//           LeadFields.push({
//             Attribute: "mx_Street1",
//             Value: values.address1,
//           });
//           LeadFields.push({
//             Attribute: "mx_Street2",
//             Value: values.address2,
//           });
//           LeadFields.push({
//             Attribute: "mx_City",
//             Value: values.city,
//           });
//           LeadFields.push({
//             Attribute: "mx_State",
//             Value: values.state,
//           });
//           LeadFields.push({
//             Attribute: "mx_Zip",
//             Value: values.zip,
//           });
//           LeadFields.push({
//             Attribute: "mx_Relationship_Manager",
//             Value: values.relationshipmanager,
//           });
//           LeadFields.push({
//             Attribute: "mx_Portal_Password",
//             Value: values.password,
//           });
//           LeadFields.push({
//             Attribute: "mx_Portal_Confirm_Password",
//             Value: values.confirmpassword,
//           });
//           LeadFields.push({
//             Attribute: "mx_Lead_Type",
//             Value: "Channel Partner",
//           });
//           console.log(LeadFields);
//           Register(
//             {
//               LeadFields: LeadFields,
//             },
//             {
//               changeState: this.changeState,
//               resetForm: formikActions.resetForm,
//               props: this.props.props.myprops,
//             },
//             {
//               EmailAddress: values.email,
//               Password: values.password,
//             }
//           );
//         }}
//       >
//         {(props) => {
//           const { isSubmitting } = props;
//           // console.log(isSubmitting);
//           return (
//             <Form className="form-container" data-aos="zoom-in">
//               {this.state.formError ? this.state.formError : null}
//               <div className="two-coloum">
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="channelpartnername">
//                     Channel Partner Name
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="Channel Partner Name"
//                     autoComplete="false"
//                     name="channelpartnername"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage
//                       name="channelpartnername"
//                       className="errorDiv"
//                     />
//                   </div>
//                 </div>
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="phonenumber">
//                     Phone Number
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="Phone Number"
//                     autoComplete="false"
//                     name="phonenumber"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="phonenumber" className="errorDiv" />
//                   </div>
//                 </div>
//               </div>
//               <div className="two-coloum">
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="email">
//                     Email
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="email"
//                     placeholder="Email Addresss"
//                     autoComplete="false"
//                     name="email"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="email" className="errorDiv" />
//                   </div>
//                 </div>
//                 <div className="form-group  two-coloum-width">
//                   <label htmlFor="pancardno">
//                     PAN Card No
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="PAN Card No"
//                     autoComplete="false"
//                     name="pancardno"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="pancardno" className="errorDiv" />
//                   </div>
//                 </div>
//               </div>
//               <div className="two-coloum">
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="reraid">
//                     RERA ID
//                     <sup>
//                       <span className="star-color pl-lg-1"></span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="RERA ID"
//                     autoComplete="false"
//                     name="reraid"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="reraid" className="errorDiv" />
//                   </div>
//                 </div>
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="address1">
//                     Address 1
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="Address 1"
//                     autoComplete="false"
//                     name="address1"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="address1" className="errorDiv" />
//                   </div>
//                 </div>
//               </div>
//               <div className="two-coloum">
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="address2">
//                     Address 2
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="Address 2"
//                     autoComplete="false"
//                     name="address2"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="address2" className="errorDiv" />
//                   </div>
//                 </div>
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="city">
//                     City
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="City"
//                     autoComplete="false"
//                     name="city"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="city" className="errorDiv" />
//                   </div>
//                 </div>
//               </div>
//               <div className="two-coloum">
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="address2">
//                     State
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="State"
//                     autoComplete="false"
//                     name="state"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="state" className="errorDiv" />
//                   </div>
//                 </div>
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="city">
//                     Zip
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     type="text"
//                     placeholder="Zip"
//                     autoComplete="false"
//                     name="zip"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="zip" className="errorDiv" />
//                   </div>
//                 </div>
//               </div>
//               <div className="form-group">
//                 <label htmlFor="relationshipmanager">
//                   Relationship Manager
//                   <sup>
//                     <span className="star-color pl-lg-1">.</span>
//                   </sup>
//                 </label>
//                 <Field as="select" name="relationshipmanager">
//                   <option value=""></option>
//                   {this.state.userListData.map((data, index) => {
//                     return <option value={data}>{data}</option>;
//                   })}
//                 </Field>
//                 <div className="errorbox">
//                   <ErrorMessage
//                     name="relationshipmanager"
//                     className="errorDiv"
//                   />
//                 </div>
//               </div>

//               <div className="two-coloum">
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="password ">
//                     Password
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     autoComplete="new-password"
//                     type="password"
//                     placeholder="Password"
//                     name="password"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="password" className="errorDiv" />
//                   </div>
//                 </div>
//                 <div className="form-group two-coloum-width">
//                   <label htmlFor="confirmpassword ">
//                     Confirm Password
//                     <sup>
//                       <span className="star-color pl-lg-1">.</span>
//                     </sup>
//                   </label>
//                   <Field
//                     autoComplete="new-password"
//                     type="password"
//                     placeholder="Confirm Password"
//                     name="confirmpassword"
//                   ></Field>
//                   <div className="errorbox">
//                     <ErrorMessage name="confirmpassword" className="errorDiv" />
//                   </div>
//                 </div>
//               </div>
//               <div className="">
//                 <button
//                   type="submit"
//                   disabled={this.state.isSubmitting}
//                   className="fbtn btn-primary btn-pill mt-2"
//                 >
//                   {this.state.isSubmitting ? "Loading..." : "Sign Up"}
//                 </button>
//               </div>
//               <div className="text-center w-100 mt-4 d-flex justify-content-center">
//                 Already have an Account?
//                 <div
//                   className="blue-text"
//                   onClick={() => {
//                     this.props.props.changeState({ tab: 1 });
//                   }}
//                 >
//                   Login
//                 </div>
//               </div>
//             </Form>
//           );
//         }}
//       </Formik>
//     );
//   }
// }

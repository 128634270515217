import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  generateSampleCSVData,
  removeUploadedFile,
  fetchFileDetails,
} from "../../logic.js";
import { ACCOUNT_SETTINGS } from "../../Settings/Import_Leads_Settings.js";
import backIcon from "../../../../images/back-image.png";
import uploadIcon from "../../../../images/upload-image.png";
import downloadIcon from "../../../../images/download-image.png";
import { Button, Collapse } from "react-bootstrap";

let UploadFile = ({ bulkLeadImport, STATE_VALUES, CHANGE_STATE_VALUES }) => {
  const [open, setOpen] = useState(false);
  let {
    MAX_ROWS_ALLOWED: maxRowsAllowed,
    MAX_SIZE_ALLOWED: maxFileSizeAllowed,
    ENABLE_SAMPLE_CSV_DOWNLOAD_BUTTON: enableSampleCSVDownloadButton,
    ENABLE_GO_BACK_BUTTON: enableGoBackButton,
  } = ACCOUNT_SETTINGS;

  let { chooseFileRef, totalLeads, uploadedFileDetails, uploadButtonText } =
    STATE_VALUES;

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 my-5 import-leads-col">
            <div className="container import-leads-container">
              {/* MAIN HEADING */}
              <div className="row">
                <div className="col-12 p-0">
                  <div className="import-leads-heading-wrapper">
                    <div className="import-leads-heading">
                      <i class="fas fa-file-import fa-lg"></i>Import Referrals
                    </div>
                    <div className="header-buttons-wrapper">
                      {/* {enableSampleCSVDownloadButton && (
                        <button
                          className="header-download-sample-csv-button"
                          onClick={generateSampleCSVData}
                        >
                          Download Sample CSV
                        </button>
                      )} */}
                      {enableGoBackButton && (
                        <Link className="header-back-button" to={"/"}>
                          <img src={backIcon} alt=''/>
                          Back
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* FILE UPLOAD SECTION */}
              <div className="row">
                <div className="col-md-12 col-lg-7 p-0">
                  <form
                    onSubmit={(uploadFileForm) =>
                      bulkLeadImport(uploadFileForm)
                    }
                    id="f1"
                  >
                    <div className="file-upload-section">
                      {/* TOTAL LEADS INFO */}
                      {/* <div className="total-leads-parent-wrapper">
            <div className="total-leads-sub-wrapper">
             <div className="total-leads-heading">
              Total Leads
            </div>
             <div className="total-leads-value">
              {totalLeads}
             </div>
            </div>
           </div> */}

                      {/* FILE UPLOAD BUTTON */}
                      <div className="upload-file-heading">
                        <span>Upload CSV file here</span>
                      </div>
                      <div className="upload-file-container">
                        <div className="upload-btn-wrapper">
                          <input
                            type="file"
                            ref={chooseFileRef}
                            className="d-none"
                            id="cf1"
                            disabled={uploadButtonText === "Processing"}
                            onChange={() =>
                              fetchFileDetails(
                                STATE_VALUES,
                                CHANGE_STATE_VALUES
                              )
                            }
                          />
                          <label htmlFor="cf1" className="choose-file-button">
                            <img src={uploadIcon}></img>
                            Browse File
                          </label>

                          <button
                            type="submit"
                            className="upload-file-button"
                            disabled={uploadButtonText === "Processing"}
                          >
                            {uploadButtonText}
                          </button>
                        </div>
                        <div className="total-leads">
                          <span>Total Leads: {totalLeads}</span>
                        </div>
                        <div className="upload-file-wrapper">
                          {uploadedFileDetails?.fileName && (
                            <div className="uploaded-file-details">
                              <div className="uploaded-file-name">
                                {uploadedFileDetails.fileName}
                              </div>

                              <div className="uploaded-file-size">
                                {uploadedFileDetails.fileSize}
                              </div>

                              {/* Only visible when Button text is "Upload" */}
                              {uploadButtonText === "Upload" && (
                                <div className="remove-uploaded-file-icon">
                                  <span
                                    onClick={() =>
                                      removeUploadedFile(CHANGE_STATE_VALUES)
                                    }
                                  >
                                    <AiOutlineCloseCircle
                                      color="#AAB6C2"
                                      size="20px"
                                    />
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-12 col-lg-5 p-0">
                  <div className="instruction-wrapper">
                    <div className="heading">
                      <span>Instructions</span>
                    </div>
                    <hr className="instruction-horizontal-line"></hr>
                    <div className="instruction-points">
                      <span>
                        <b className="bullet">•</b> You can upload files only{" "}
                        <b> CSV format. </b>
                      </span>
                      <span>
                        <b className="bullet">•</b> Maximum <b>1000 rows</b> are
                        allowed.
                      </span>
                      <span>
                        <b className="bullet">•</b> Maximum file size allowed is{" "}
                        <b>1024 KB</b>.
                      </span>
                      <span>
                        <b className="bullet">•</b> Supports CSV file with{" "}
                        <b>comma separated</b> values.
                      </span>
                    </div>
                    <div className="link-wrapper">
                      <span>
                        <a href="/Instruction" target="_blank">
                          View Detailed Instructions
                        </a>
                        <i class="fas fa-chevron-right fa-xs"></i>
                      </span>
                      <span>
                        
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 p-0">
                  <div className="info-container">
                    <div className="heading" onClick={() => setOpen(!open)}>
                      <div className="icon-wrapper">
                      {!open ? (
                        <i class="fas fa-chevron-right fa-xs"></i>
                      ) : (
                        <i class="fas fa-chevron-down fa-xs"></i>
                      )}
                      </div>
                      <span>What should be the Format of CSV?</span>
                    </div>
                  </div>
                  <div className={`details ${open ? "show-details" : "hide-details"}`}>
                    <div className="content">
                      <span>
                        The format of your CSV is important. Please arrange your
                        CSV file to match the column headers shown below and
                        then save the file as CSV
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={generateSampleCSVData}
                      >
                        <img src={downloadIcon} /> Download Sample
                      </span>
                    </div>
                    <div className="instruction-table-2">
                      <table>
                        <tr>
                          <th>FirstName</th>
                          <th>LastName</th>
                          <th>Phone</th>
                          <th>EmailAddress</th>
                          <th>mx_Season</th>
                          <th>mx_course_campus_name</th>
                          <th>mx_course_name</th>
                          <th>mx_course_campus_college_name</th>
                      
                        </tr>
                        <tr>
                          <td>PS1</td>
                          <td>Sample1</td>
                          <td>9766620546</td>
                          <td>lsq.test2@gmail.com</td>
                          <td>2020</td>
                          <td>LPU</td>
                          <td>FullStack</td>
                          <td>LPU1</td>
                        
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* IMPORTANT INFORMATION  */}
              {/* <div className="row">
                <div className="col-12 p-0">
                  <div className="important-information">
                    Max rows allowed are {maxRowsAllowed}. Max file size allowed
                    is {maxFileSizeAllowed} KB. Supports CSV file with comma
                    separated values.
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFile;

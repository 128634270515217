import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { withRouter, Link } from "react-router-dom";
import { API_GetLeadByCriteria } from "../Dashboard/test";
//quick start to mdbreact https://mdbootstrap.com/docs/react/getting-started/quick-start/
import React, { Component } from "react";
import Spinner from "../Spinner";
import "./dashboard.css";
import API_Payload from "./setting";
import Moment from "moment";
import utility from "../api-caller/utility";
let tempp = [];
class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      won: 0,
      lost: 0,
      prospect: 0,
      showSpinner: true,
      rejected: 0,
      accepted: 0,
      Page: 1,
      count: 0,
      rowData: [],
      flag: true,
      flagcounter: false,
      tempp: [],
    };
  }

  componentDidMount() {
    //function to get all the leads and set it to the table
    this.getAllLeads();
  }

  getAllLeads = () => {
    var leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
      : this.props.history.push("/");
    //Payload for the API call
    let data = {
      Parameter: {
        LookupName: "mx_ReferralleadId",
        LookupValue: leadId,
        SqlOperator: "=",
      },
      Columns: API_Payload.Columns,
      Sorting: API_Payload.Sorting,
      Paging: {
        PageIndex: this.state.Page,
        PageSize: 1000,
      },
    };

    //Search Lead by Criteria API
    API_GetLeadByCriteria(data).then((json) => {
      //getting the json data row count
      this.props.props.changeState({ rowCount: json.data.message.length });
      console.log("row in datatable--", json.data.message.length);
      this.setState({
        count: this.state.count + json.data.message.length,
       
      });

      //modifying rows
      json.data.message.forEach((row) => {
        this.state.rowData.push(row);
        let lastname = row.LastName
          ? row.LastName.charAt(0).toUpperCase() + row.LastName.slice(1)
          : "";
        let firstname = row.FirstName
          ? row.FirstName.charAt(0).toUpperCase() + row.FirstName.slice(1)
          : "";
        row.mx_Fullname = firstname + " " + lastname;

        row.EmailAddress = row.EmailAddress ? row.EmailAddress : " ";
        row.ProspectStage = row.ProspectStage ? row.ProspectStage : " ";
        row.Mobile = row.Mobile ? row.Mobile : " ";
        row.CreatedOn = Moment(row.CreatedOn).format("DD-MM-YYYY");
        row.LeadLastModifiedOn = Moment(row.LeadLastModifiedOn).format("DD-MM-YYYY");
        row.Season =  row.mx_Season ? row.mx_Season : " ";
        row.Branch =  row.mx_course_campus_name ? row.mx_course_campus_name : " ";
        row.Course =  row.mx_course_name ? row.mx_course_name : " ";
        row.Campus =  row.mx_course_campus_college_name ? row.mx_course_campus_college_name : " ";
        const state = row.ProspectStage.toLowerCase();
        if (state === "ok to disburse") {
          row.status = (
            <div style={{ color: "#6142C9", fontWeight: "400" }}>
              {row.ProspectStage}
            </div>
          );
          this.setState({ accepted: this.state.accepted + 1 });
        } else if (state === "disqualified after signup"|| state === "disqualified") {
          row.status = (
            <div style={{ color: "#24B47E", fontWeight: "400" }}>
              {row.ProspectStage}
            </div>
          );

          this.setState({ rejected: this.state.rejected + 1 });
        }
      });

      //Incrementing the Page Index for next round of API call
      this.setState({ Page: this.state.Page + 1 });
      console.log("darata", json.data.message);

      //storing and appending the API response data rows
      // this.setState({ ...this.state, rowData: json.data.message });
      console.log("state count", this.state.count);
      // this.setState({ rowData: [...this.state.rowData, json.data.message] });

      // this.state.rowData.push(json.data.message);

      //storing the total lead count
      // this.setState({ ...this.state, prospect: json.data.message.length });

      //condition to check for response size
      if (json.data.message.length >= 1000) {
        this.getAllLeads();
      } else {
        // this.setState({ rowData: this.state.tempp });
        this.setState({ ...this.state, prospect: this.state.rowData.length });
        //once complete storing the entire data, preparing payload for the table to pass
        var data1 = {
          columns: API_Payload.TableData,
          rows: this.state.rowData,
        };
        console.log("final data", this.state.rowData);
        this.setState({ data: data1,
          showSpinner: false });
      }
    });
  };

  render() {
    if (this.state.showSpinner)
      return <Spinner show={this.state.showSpinner} />;
    return (
      <div className="container">
        <div className="row dashboard-header-wrapper">
          <div className="col-12 col-md-6">
            <div className="dashboard-heading">My Leads</div>
          </div>
          <div className="col-12 col-md-6">
            <div className="dashboard-buttons-wrapper">
              <button
                className="add-new-lead-button"
                onClick={() => this.props.props.showForm()}
              >
                Add New Lead
              </button>
              <a href="/importLead" className="import-leads-button">
                Import Leads
              </a>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-between status-cards"
          style={{ marginTop: "30px", marginBottom: "16px" }}
        >
          <div className="col-md-4 col-6 text-center ca">
            <div
              className="card card-body prospect p-1 pt-4 pb-4"
              data-aos="zoom-in"
            >
              <div style={{ color: "#6142C9" }}>Submitted Leads</div>
              <div>{this.state.prospect}</div>
            </div>
          </div>
          <div className="col-md-4 col-6 text-center ca">
            <div
              className="card card-body won p-1 pt-4 pb-4"
              data-aos="zoom-in"
            >
              <div style={{ color: "#24B47E" }}>Accepted Leads</div>
              <div>{this.state.accepted}</div>
            </div>
          </div>
          <div className="col-md-4 col-6 text-center ca  mobile-margin">
            <div
              className="card card-body lost p-1 pt-4 pb-4"
              data-aos="zoom-in"
            >
              <div style={{ color: "#FF3636" }}>DISQUALIFIED LEADS</div>
              <div>{this.state.rejected}</div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in">
          <MDBDataTable scrollX striped bordered data={this.state.data} />
        </div>
      </div>
    );
  }
}

export default withRouter(DataTable);

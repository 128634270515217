import React from "react";
import * as yup from "yup";

function GetForgotVaidationSchema() {
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Required"),
  });
  // console.log('vlaidlogin', validationSchema);
  return validationSchema;
}

function GetVaidationSchema() {
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const panCardRegExp = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Required"),
    phonenumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    channelpartnername: yup.string().required("Required"),
    pancardno: yup
      .string()
      .matches(panCardRegExp, "Expected format: AAAPL1234C")
      .required("Required"),
    address1: yup.string().required("Required"),
    address2: yup.string().required("Required"),
    city: yup.string().required("Required"),
    zip: yup.string().required("Required"),
    state: yup.string().required("Required"),
    relationshipmanager: yup.string().required("Required"),
    password: yup
      .string()
      .min(4, "Please choose a password with at least 4 characters ")
      .required("Required"),
    confirmpassword: yup
      .string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Both password need to be the same"),
      })
      .required("Required"),
  });

  return validationSchema;
}

function GetLoginVaidationSchema() {
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Required"),
    password: yup
      .string()
      .min(4, "Please choose a password with at least 4 characters ")
      .required("Required"),
  });
  // console.log('vlaidlogin', validationSchema);
  return validationSchema;
}

export default {
  GetForgotVaidationSchema,
  GetLoginVaidationSchema,
  GetVaidationSchema,
};

import React, { Component } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { ResetPassword } from "../api-caller/apiCaller";
import { withRouter } from "react-router-dom";
import leadsquared from "../../images/leadsquared.svg";
import logo from "../../images/logo.png";
class ResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: "",
      isSubmitting: false,
    };
    this.changeState = this.changeState.bind(this);
  }
  changeState(stateInfo) {
    this.setState(stateInfo);
    this.setState({ isSubmitting: false });
  }
  render() {
    return (
      <div className="form-content">
        <div className="log-web">
          <img src={logo} alt="leadsquared" />
        </div>
        <div className="bg-web">
          <div className="text-container">
            <div>
              <p className="tt">Reset Password</p>
              {/* <p className='bt '>Login to add and view referrals</p> */}
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="log-mobile">
            <img src={logo} alt="leadsquared" />
          </div>
          <div className="container-fluid">
            <Formik
              initialValues={{ confirmpassword: "", password: "" }}
              onSubmit={(values, formikActions) => {
                this.setState({ isSubmitting: true });
                console.log("inside submit ", values, formikActions);
                var uRLSearchParams = new URLSearchParams(
                  window.location.search
                );
                var LeadId = uRLSearchParams.get("LeadId");
                var TemporaryPassword = uRLSearchParams.get(
                  "TemporaryPassword"
                );

                ResetPassword(
                  {
                    LeadId: LeadId,
                    TemporaryPassword: TemporaryPassword,
                    Password: values.password,
                  },
                  {
                    changeState: this.changeState,
                    props: this.props,
                  }
                );
                formikActions.setSubmitting(false);
              }}
              validationSchema={GetVaidationSchema}
            >
              <Form className="form-container" data-aos="zoom-in">
                <p className="form-heading">Reset Password</p>
                {this.state.formError ? this.state.formError : null}

                <div className="form-group">
                  <span>
                    <label
                      htmlFor="password "
                      style={{
                        color: " #454F5B",
                        fontWeight: "bold",
                      }}
                    >
                      New Password
                      <sup>
                        <span className="star-color pl-lg-1">.</span>
                      </sup>
                    </label>
                  </span>

                  <Field
                    autoComplete="new-password"
                    type="password"
                    placeholder="Password"
                    name="password"
                  ></Field>
                  <div className="errorbox">
                    <ErrorMessage name="password" className="errorDiv" />
                  </div>
                </div>
                <div className="form-group">
                  <span>
                    <label
                      htmlFor="confirmpassword "
                      style={{
                        color: " #454F5B",
                        fontWeight: "bold",
                      }}
                    >
                      Confirm Password
                      <sup>
                        <span className="star-color pl-lg-1">.</span>
                      </sup>
                    </label>
                  </span>

                  <Field
                    autoComplete="new-password"
                    type="password"
                    placeholder="Re-Type Password"
                    name="confirmpassword"
                  ></Field>
                  <div className="errorbox">
                    <ErrorMessage name="confirmpassword" className="errorDiv" />
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    disabled={this.state.isSubmitting}
                    className="fbtn btn-primary btn-pill"
                  >
                    {this.state.isSubmitting ? "Loading..." : "Reset Password"}
                  </button>
                </div>
                {/* <div className='text-center w-100 mt-3'>
                    <span
                      className='blue-text'
                      onClick={() => {
                        this.props.history.push('/');
                      }}>
                      Login
                    </span>
                  </div> */}
              </Form>
            </Formik>
          </div>
          <footer className="form-footer-section footer">
            <div className="container d-flex justify-content-between">
              <span></span>
              <span>
                Powered by
                <img src={leadsquared} alt="leadsquared" />
              </span>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
function GetVaidationSchema() {
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(4, "Please choose a password with at least 4 characters ")
      .required("Required"),
    confirmpassword: yup
      .string()
      .min(4, "Please choose a password with at least 4 characters ")
      .required("Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  // console.log('vlaidlogin', validationSchema);
  return validationSchema;
}
export default withRouter(ResetForm);

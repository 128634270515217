import React, { useEffect } from 'react'

// Importing Components
import UploadFile from "./Components/File_Upload_Section/Upload_File_UI.js";
import UploadedFileLogs from "./Components/Uploaded_File_Logs/Uploaded_File_Logs_UI.js";
import ModalBox from "./Components/Modal/index.js";
import Header from "../Dashboard/Header.js";

// Importing CSS Files
import "./Components/File_Upload_Section/Upload_File_UI.css";
import "./Components/Uploaded_File_Logs/Uploaded_File_Logs_UI.css";
import "./Components/Modal/modal.css";


// Importing Settings
import "./Settings/CSS_Settings.css";
import { ACCOUNT_SETTINGS } from "./Settings/Import_Leads_Settings";

// Importing Logic
import { useStateManagement, fetchFileDetails, removeUploadedFile, readFileData, validateFile, convertInto_PayloadFormat, INTITIATE_LAPP_CALL_PROCESS, logsViewButtonToggler } from "./logic.js";
import PoweredByLeadSquared from '../PoweredByLeadSquared/index.js';

let ImportLeads = (props) => {


 /* ==================================================
 **************** STATE MANAGEMENT  ******************
 =====================================================*/

 let [STATE_VALUES, CHANGE_STATE_VALUES] = useStateManagement();

 let { chooseFileRef,
  uploadedFileDetails,
  processLeads,
 } = STATE_VALUES;

 let { setTotalLeads,
  setLastUploadedFileDetails,
  setUploadButtonText,
  setFileLogs,
  setProcessLeads,
  setDisplayExecutingRow,
  setDisplayModalBox,
  setModalContent } = CHANGE_STATE_VALUES;





 /* ==================================================
************* LAPP CALL via USE EFFECT  **************
=====================================================*/


 // Calling LAPP until processLeads are not finished.
 //  In Signle Call, 50 Leads are getting processed

 useEffect(() => {
  if (processLeads.length > 0)
   setDisplayExecutingRow(true);
  INTITIATE_LAPP_CALL_PROCESS(STATE_VALUES, CHANGE_STATE_VALUES);
 }, [processLeads])





 /* ==================================================
 **************  UPLOAD BUTTON FUNCTION **************
 =====================================================*/

 // Getting triggered on click of UPLOAD Button

 let bulkLeadImport = async (uploadFileForm) => {

  // Preventing Page Refresh on Form Submission
  uploadFileForm.preventDefault();

  // Upload Button will be Disabled 
  setUploadButtonText("Processing");

  // Accessing <input type="file"> element
  let fileUpload = chooseFileRef.current;

  // Reading File Data
  let fileData = await readFileData(fileUpload);
  // console.log("FILE DATA", fileData)

  // Display Error Message for Invalid File Extenstion
  if (fileData === "Invalid File") {
   setUploadButtonText("Upload");
   if (fileUpload.value === "") {
    setDisplayModalBox(true);
    setModalContent({
     headerContent: "File Required",
     bodyContent: "Please Upload any File"
    })
   }
   else {
    removeUploadedFile(CHANGE_STATE_VALUES);
    setDisplayModalBox(true);
    setModalContent({
     headerContent: "Invalid File",
     bodyContent: "Invalid File Format. Only CSV File Allowed"
    })
   }
  }
  // If File Extension is CORRECT
  else {

   // Checking MAX SIZE ALLOWED, EMPTY FILE, MAX ROWS ALLOWED 
   let [leadsArray, errorMessage] = validateFile(fileData, uploadedFileDetails.fileSize);

   // Displaying Error Message if File Data is Invalid
   if (errorMessage) {
    removeUploadedFile(CHANGE_STATE_VALUES);
    setUploadButtonText("Upload");
    setDisplayModalBox(true);
    setModalContent(errorMessage);
   }
   // If File Data is VALID, then 
   else {

    // Converting Leads Array Into Payload Format
    let [payloadFormat, leadCount, emptyFieldCount, foundMandatoryColumns] = convertInto_PayloadFormat(leadsArray);

    // Display Error Message if found empty fields in between
    if (emptyFieldCount > 0) {
     removeUploadedFile(CHANGE_STATE_VALUES);
     setUploadButtonText("Upload");
     setDisplayModalBox(true);
     setModalContent({
      headerContent: "Empty Fields",
      bodyContent: "Uploaded CSV file contains EMPTY FIELDS",
     });
    }
    // Mandatory COLUMN NAMES in CSV File
    else if (foundMandatoryColumns === false) {
     removeUploadedFile(CHANGE_STATE_VALUES);
     setUploadButtonText("Upload");
     setDisplayModalBox(true);
     setModalContent({
      headerContent: "Mandatory Columns Not Found",
      bodyContent: `One of the Column Name might be missing or wrong in the uploaded CSV File. Please check instruction page for correct format.`,
     });
    }
    // If ALL Fields are Occupied (Filled), then
    else {
     // Updating Total Lead Count in State
     setTotalLeads(leadCount);

     // Calling API (via UseEffect) if No field is empty
     if (emptyFieldCount === 0) {
      setFileLogs([])
      setLastUploadedFileDetails({});
      setProcessLeads(payloadFormat);
     }
    }
   }
  }
 }




 /* ==================================================
 *******************  PROPS OBJECTS ******************
 =====================================================*/

 let uploadFileProps = {
  bulkLeadImport, // (Func) => Gets called onSubmit of Form
  STATE_VALUES,
  CHANGE_STATE_VALUES,
 }

 let uploadedFileLogsProps = {
  logsViewButtonToggler,
  STATE_VALUES
 }

 let modalBoxProps = {
  STATE_VALUES,
  CHANGE_STATE_VALUES
 }



 /* ==================================================
  *******************  UI Components ******************
  =====================================================*/

 return (
  <>

   <div className="import-leads-wrapper">
    <Header />

    {/* File Upload Section */}
    <UploadFile {...uploadFileProps} />

    {/* Uploaded File Logs Section */}
    <UploadedFileLogs {...uploadedFileLogsProps} />

    <ModalBox {...modalBoxProps} />
   </div>
   <PoweredByLeadSquared />
  </>
 )
}
export default ImportLeads;
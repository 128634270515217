import utility from "../../api-caller/utility";
  

let LEAD_ID = utility.IsAuthSessionInfoCookiePresent
  ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
  : this.props.history.push("/");

// const userName = localStorage.getItem("username") ? localStorage.getItem("username") : "";
let ACCOUNT_SETTINGS =
{
  MAX_ROWS_ALLOWED: 999,
  MAX_SIZE_ALLOWED: 1024, // Value in KB (File Size)

  ADD_COLUMNS: {
    "mx_ReferralleadId": LEAD_ID, // Use Agent Lead ID
    "mx_Lead_consultant_new": localStorage.getItem("userNameValue")
    // "mx_Affiliate_Name": '', 
    // "Source": "" 
  },

  // All field values of all columns will be required
  MARK_ALL_COLUMNS_REQUIRED: true,

  // LAPP that will trigger Import Bulk Lead API inside it
  LAPP_SETTINGS: {
    LAPP_API_URL: "https://lapps-in21.leadsquared.com/execute?name=da_47048_18b5bddd&stage=Live",
    METHOD: "POST",
    HEADERS: {
      "Content-Type": "application/json",
      "x-api-key": "goXHumon7v5XtpiTpfflh7idNlzIKQ1OaavlFmk7",
    },
  },

  // Value of this SchemaName will be used in File Logs
  // Eg: EmailAddress, Phone, CUSTOM_FIELD
  UNIQUE_IDENTIFIER_SCHEMA_NAME: "EmailAddress",

  // These Column Names should be present in CSV file
  MANDATORY_COLUMN_NAMES: [
  "FirstName", "LastName", "Phone",
  "EmailAddress"
  , "mx_Season", "mx_course_campus_name",
  "mx_course_name", "mx_course_campus_college_name"
],


  ENABLE_SAMPLE_CSV_DOWNLOAD_BUTTON: true,
  ENABLE_GO_BACK_BUTTON: true,

  // Sample CSV File Data
  SAMPLE_CSV_DATA: {
    fileName: "SampleCSV_File.csv",
    columnNames: [
      "FirstName",
      "LastName",
      "Phone",
      "EmailAddress",
      "mx_Season",
      "mx_course_campus_name",
      "mx_course_name",
      "mx_course_campus_college_name"
    ],
    rowData: [
      // Row 1 Data
      ["PS 1", "Sample 1", "9766620546", "lsq.test2@gmail.com","2020","LPU","FullStack","LPU1"],
      // Row 2 Data
      ["PS 2", "Sample 2", "1231231232", "ps2@gmail.com","2020","LPU","FullStack","LPU1"],
      // Row 3 Data
      ["PS 3", "Sample 3", "1231231233", "ps3@gmail.com","2020","LPU","FullStack","LPU1"],
      // Row 4 Data
      ["PS 4", "Sample 4", "1231231234", "ps4@gmail.com","2020","LPU","FullStack","LPU1"],
      // Row 5 Data
      ["PS 5", "Sample 5", "1231231235", "ps5@gmail.com","2020","LPU","FullStack","LPU1"],
    ],
  }
}

export { ACCOUNT_SETTINGS };
import React from "react";
import { Modal } from "react-bootstrap";

function ModalBox({ STATE_VALUES, CHANGE_STATE_VALUES }) {

 let { displayModalBox, modalContent, } = STATE_VALUES
 let { setDisplayModalBox } = CHANGE_STATE_VALUES;

 return (
  <>
   <Modal
    show={displayModalBox}
    onHide={() => setDisplayModalBox(false)}
   >
    <Modal.Header className="d-flex justify-content-center">
     <div className="modal-heading-text">
      {modalContent.headerContent}
     </div>
    </Modal.Header>

    <Modal.Body className="d-flex justify-content-center text-center">
     {modalContent.bodyContent}
    </Modal.Body>

    <Modal.Footer className="d-flex justify-content-center">
     <button className="modal-close-button"
      onClick={() => setDisplayModalBox(false)}>
      Close
     </button>
    </Modal.Footer>
   </Modal>
  </>
 )
}

export default ModalBox;

import React from "react";
import "./css/App.css";
import LandingPage from "./components/landing-page/LandingPage.js";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import SessionManager from "./components/SessionManager";
import ResetPassword from "./components/Forms/ResetPassword";
import LeadForm from "./components/Forms/LeadForm";
// import ImportLeadDashboard from "./components/Dashboard/ImportLeadDashboard";
// import ImportBulklead from "./components/Dashboard/importLead";
import ImportBulklead from "./components/Dashboard/ImportBulkLead";
import Error404 from "./components/error-404/Error404";
import "bootstrap/dist/css/bootstrap.css";
import "aos/dist/aos.css";
import "react-bootstrap";
import Instructions from "./components/Import_Leads/Components/File_Upload_Section/Instructions";

import ImportLeads from "./components/Import_Leads/index";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/Dashboard" component={Dashboard} />
          <Route exact path="/SessionManager" component={SessionManager} />
          <Route exact path="/ResetPassword" component={ResetPassword} />
          <Route exact path="/Form" component={LeadForm} />
          {/* <Route exact path="/importLead" component={ImportBulklead} /> */}
          <Route exact path="/importLead" component={ImportLeads} />
          <Route exact path="/Instruction" component={Instructions} />
          {/* <Route exact path="/importBulkLead" component={ImportBulklead} /> */}
          <Route component={Error404} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;

import axios from "axios";
import AuthHandler from "../api-caller/utility";
import constants from "../constants";

var lsqApiInstance = axios.create({
  baseURL: constants.LSQAPI.baseURL,
  timeout: 90000,
  headers: { Authorization: AuthHandler.GetAuthKey() },
});

// const API_GetLeadsById = (payload) => {​​​​​​​​
//    return API_interceptor_Lapp({​​​​​​​​
//     method: "get", url: LSQ_API.ENDPOINTS.GET_LEAD_BY_ID,
//     params: {​​​​​​​​ id: payload.leadId, }​​​​​​​​, }​​​​​​​​)}​​​​​​​​;

// const API_GetLeadActivity = (payload) => {​​​​​​​​ return API_interceptor_Lapp({​​​​​​​​ method: "post", url: LSQ_API.ENDPOINTS.GET_LEAD_ACTIVITY, params: {​​​​​​​​ leadId: payload.leadId, }​​​​​​​​, data: {​​​​​​​​ Parameter: {​​​​​​​​ ActivityEvent: payload.activityEvent, }​​​​​​​​, }​​​​​​​​, }​​​​​​​​);}​​​​​​​​;
// const API_SignnowAccessTokenLapp = (body) => {​​​​​​​​
//   return lsqApiInstance({​​​​​​​​ method: "POST", headers: {​​​​​​​​ "x-api-key": "PJsVvYdokA2B2CL3yKHUC8CsMgLo3U72McU6F363",
//   "Content-Type": "application/json", }​​​​​​​​, url: "https://lapps-us11.leadsquared.com/execute?name=da_36410_3b98f543&stage=Test",
//    data: body, json: true, }​​​​​​​​);}​​​​​​​​;
// const API_CreateActivity = (payload) => {​​​​​​​​ return API_interceptor_Lapp({​​​​​​​​ method: "post", url: LSQ_API.ENDPOINTS.CREATE_ACTIVITY, params: {​​​​​​​​}​​​​​​​​, data: payload, }​​​​​​​​)}​​​​​​​​;
// const API_UpdateActivity = (payload) => {​​​​​​​​ return API_interceptor_Lapp({​​​​​​​​ method: "post", url: LSQ_API.ENDPOINTS.UPDATE_ACTIVITY, params: {​​​​​​​​}​​​​​​​​, data: payload, }​​​​​​​​)}​​​​​​​​;
// const API_ActivityDetailsById = (payload) => {​​​​​​​​ return API_interceptor_Lapp({​​​​​​​​ method: "get", url: LSQ_API.ENDPOINTS.GET_ACTIVITY_DETAILS_BY_ID, params: {​​​​​​​​ activityId: payload.activityId, getFileURL: true, }​​​​​​​​, }​​​​​​​​)}​​​​​​​​;
// const API_UpdateLead = (payload) => {​​​​​​​​ return API_interceptor_Lapp({​​​​​​​​ method: "post", url: LSQ_API.ENDPOINTS.UPDATE_LEAD, params: {​​​​​​​​ leadId: AuthHandler.GetLeadId(), }​​​​​​​​, data: payload, }​​​​​​​​)}​​​​​​​​;
// //https://lapps-us11.leadsquared.com/executebylapptoken?name=da_36410_00c59593&stage=Live&xapikey=c1ce49970a7a4e84ac3c33ec8a145d6f

const API_interceptor_Lapp = (body) => {
  console.log("body in inter=", body);
  const a = lsqApiInstance({
    method: "POST",
    url:
      "https://lapps-in21.leadsquared.com/execute?name=da_47048_20b6a679&stage=Test",
    data: body,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "goXHumon7v5XtpiTpfflh7idNlzIKQ1OaavlFmk7",
    },
    json: true,
  });
  console.log("a=", a);
  return a;
};

const API_GetLeadByCriteria = (payload) => {
  console.log("body=", payload);
  const b = API_interceptor_Lapp({
    method: "post",
    url: constants.LSQAPI.endPoints.GetLeadByFieldDetail,
    params: {},
    data: payload,
  });
  console.log("b=", b);
  return b;
  // return fetch(
  //   "https://lapps-in21.leadsquared.com/execute?name=da_47048_20b6a679&stage=Test",

  //   {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-api-key": "goXHumon7v5XtpiTpfflh7idNlzIKQ1OaavlFmk7",
  //     },
  //     body: JSON.stringify(payload),
  //   }
  // ).then((res) => res.json());
};

const API_BulkUpdate = (payload) => {
  console.log("body=", payload);
  const b = API_interceptor_Lapp({
    method: "post",
    url: constants.LSQAPI.endPoints.BulkImport,
    params: {},
    data: payload,
  });
  console.log("b=", b);
  return b;
  // return fetch(
  //   "https://lapps-in21.leadsquared.com/execute?name=da_47048_20b6a679&stage=Test",

  //   {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-api-key": "goXHumon7v5XtpiTpfflh7idNlzIKQ1OaavlFmk7",
  //     },
  //     body: JSON.stringify(payload),
  //   }
  // ).then((res) => res.json());
};

export { API_GetLeadByCriteria, API_BulkUpdate };

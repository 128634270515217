import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import utilityFunction from "../api-caller/utility";
import { API_Login } from "../api-caller/signing-request";
import { API_GetLeadsById } from "../api-caller/lead-activity-api";
import CardLoader from "../Skeleton/Card";
import Constants from "../constants/index";
import LoadingScreen from "../Loader/Loader";
// import { API_FormLoader } from "../../api/form-loader";

import config from "../config";

import "./style.css";
/* global lsq_setupForm */

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formCardLoader: true,
      fullScreenLoader: false,
    };
  }

  loadOverrideCSS = () => {
    // Changing the button Text
    window.lsqFormContainer.querySelector(".lsq-form-action-btn").textContent =
      "Sign Up";
    this.setState({ formCardLoader: false });
  };

  // this function will get executed after successful signup
  login = (payload) => {
    debugger;
    const that = this;
    console.log("login=", payload);
    // alert("in login", payload);

    // API_Login(payload)
    API_Login({
      Password: payload.Password,

      SearchKeyData: {
        SchemaName: "Phone",

        Value: payload.Phone,
      },
    })
      .then(function (response) {
        localStorage.clear();
        console.log("data", response);
        localStorage.setItem("username", response.data);

        var authSessionInfo = {
          isAuthenticated: true,
          leadId: response.LeadId,
          authKey: response.AuthKey,
        };
        console.log("authSessionInfo ", authSessionInfo);
        utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);

        // utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);
        // that.props.updateLoginInfo(authSessionInfo);
        // that.props.updateLeadDetails(completeLeadDetails);

        that.setState({ fullScreenLoader: false });

        // for after signup success message
        console.log("successfully  login");
        localStorage.setItem("isSuccess", true);
        // alert("success");
        that.props.history.push("/Dashboard");
        // for after signup success message
        localStorage.setItem("isSuccess", true);
      })
      .catch(function (error) {
        that.setState({ fullScreenLoader: false });
      });

    // fetch(Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.Signin, {
    //   method: "POST",
    //   body: JSON.stringify(payload),
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: config.AuthKey,
    //   },
    // })
    //   .then((Response) => Response.json())

    //   .then(function (response) {
    // localStorage.clear();
    // console.log("data", response);
    // localStorage.setItem("username", response.data);

    // var authSessionInfo = {
    //   isAuthenticated: true,
    //   leadId: response.LeadId,
    //   authKey: response.AuthKey,
    // };
    // console.log("authSessionInfo ", authSessionInfo);
    // utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);

    // // utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);
    // // that.props.updateLoginInfo(authSessionInfo);
    // // that.props.updateLeadDetails(completeLeadDetails);

    // that.setState({ fullScreenLoader: false });

    // // for after signup success message
    // console.log("successfully  login");
    // localStorage.setItem("isSuccess", true);
    // alert("success");
    // that.props.history.push("/Dashboard");
    //   })
    //   .catch(function (error) {
    //     console.log(error, "error");

    //     alert("error=2", error);

    //     that.setState({ fullScreenLoader: false });
    //   });
  };

  onLSQFormSubmissionSuccess = () => {
    window.lsqFormContainer.querySelector(".ms-message-txt").textContent =
      "Account created successfully. You are now being logged in";
    console.log(">>>> LSQ Form Submit <<<<");
    this.setState({ fullScreenLoader: true });

    const phone = document.querySelector('input[data-schemaname="Phone"]')
      .value;
    const password = document.querySelector(
      'input[data-schemaname="mx_Form_Special_Password"]'
    ).value;
    // alert("o=", email, password);
    console.log("login=", phone, password);
    this.login({ Phone: phone, Password: password });
  };

  onLSQFormSaveFailure = (e) => {
    // window.lsqFormContainer.querySelector(".ms-message-txt").textContent =
    //   "User already exists";
  };

  // formSuccessMethod = (response) => {
  //   lsq_setupForm({
  //     id: config.lsqRegistrationFormId,
  //     authKeyProvider: utilityFunction.GetAuthKey,
  //   });
  // };

  formFailureMethod = (error) => {
    console.log(error);
    window.location.href = "/";
  };

  componentDidMount() {
    // API_FormLoader(
    //   config.lsqRegistrationFormBaseURL,
    //   this.formSuccessMethod,
    //   this.formFailureMethod
    // );

    $.getScript(config.lsqRegistrationFormBaseURL)
      .done(function (data) {
        lsq_setupForm({
          id: config.lsqRegistrationFormId,
          authKeyProvider: utilityFunction.GetAuthKey,
        });
      })
      .fail(function (error) {
        window.location.href = "/";
      });

    if (window.lsqFormContainer) {
      //  window.lsqFormContainer
      //    .querySelector(".lsq-form-action-btn")
      //    .addEventListener("onclick", () => {
      //      console.log("inside click");
      //      let p = window.lsqFormContainer.querySelector(
      //        ".lsq-form-password-input-ctrl"
      //      ).value;
      //      console.log("s=", p);
      //      window.lsqFormContainer.querySelector(
      //        ".lsq-form-confirm-password-item"
      //      ).innerHTML = p;
      //      alert("inside queryselector");
      //    });

      window.lsqFormContainer.addEventListener(
        "lsqformloadcomplete",
        this.loadOverrideCSS
      );

      window.lsqFormContainer.addEventListener(
        "lsqformsubmissionsuccess",
        this.onLSQFormSubmissionSuccess
      );
      window.lsqFormContainer.addEventListener(
        "LsqFormSaveFailure",
        this.onLSQFormSaveFailure
      );
    }
  }

  render() {
    return (
      <>
        {this.state.fullScreenLoader && <LoadingScreen />}
        <div
          className={`registration-form-container registration ${
            !this.state.formCardLoader &&
            "registration-form-container-below-space"
          }`}
        >
          <div
            id="lsq-form-modal"
            ref={(elem) => (window.lsqFormContainer = elem)}
            data-form-id={config.lsqRegistrationFormId}
            className="modal-v4 fullscreen external lsq-external-form-container"
          >
            <div className="lsq-form-container-wrapper"></div>
            <div className="lsq-form-hidden-fields">
              <input
                id="lsq-api-service-url"
                name="lsq-api-service-url"
                type="hidden"
                value={config.portalBaseURL}
              />
              <input
                id="lsq-app-url"
                name="lsq-app-url"
                type="hidden"
                value={config.lsqFormBaseURL}
              />
              <input
                id="lsq-form-id-hidden"
                name="lsq-form-id-hidden"
                type="hidden"
                value={config.lsqRegistrationFormId}
              />
            </div>
          </div>
        </div>

        {this.state.formCardLoader ? (
          <CardLoader count="23" />
        ) : (
          <div className=" signup-display">
            <span>Already have an Account?</span>
            <a
              href="javascript:void(0)"
              onClick={() => this.props.props.changeState({ tab: 1 })}
              style={{ paddingLeft: "5px" }}
            >
              Login
            </a>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(RegistrationForm);

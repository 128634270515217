import React, { Component } from 'react';
import '../../css/error404.css';
import { withRouter } from 'react-router-dom';
import img from '../../images/lost.svg';

class Error404 extends Component {
  render() {
    var s = {
      height: '100%',
      width: '100%',
      background: `#2E4784 url(${img})`,

      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      position: 'absolute',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    };
    return (
      <div>
        <div style={s}></div>

        <div id='notfound'>
          <div class='notfound-bg'></div>
          <div class='notfound'>
            <div class='notfound-404'>
              <h1>
                4<span style={{ color: '#FFAD59' }}>0</span>4
              </h1>
            </div>
            <div className=''>
              <h2
                className='pl-3 pr-3 '
                style={{
                  lineHeight: '1.8'
                  // background: '#f3fafe15'
                }}>
                we are sorry, but the page you requested was not found
              </h2>
            </div>

            <div
              onClick={() => {
                this.props.history.push('/');
              }}
              class='home-btn'
              style={{ cursor: 'pointer' }}>
              Go Home
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Error404);

import { Component } from 'react';
import utility from './api-caller/utility';
import { withRouter } from 'react-router-dom';
class SessionChecker extends Component {
  constructor(props) {
    super(props);
    var leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue('authSessionState').leadId
      : this.props.history.push('/Dashboard');
    // if session expire redired user to login page
    if (leadId) {
      // console.log('auth session info present ');
      this.props.history.push('/Dashboard');
    }
  }
  render() {
    return null;
  }
}
export default withRouter(SessionChecker);

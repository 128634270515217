var config = {
  AuthKey:
    "UlBjYU5NSUhUS0ZhYnViUkhvU2tSbm00WkNRejgwVWhCUmN3Z3JxQ1pEU0g1dEdYUVpaQ0lZK3FZZnhQc3diSUNaQUV1N0NRSWdLdGIxSUZuWGxXWXR3Q0dLeW80T0czcUkwRk5sVExidTJXYXR5UytqOW82RWhkV2liVVYveGpkYjFMT2oxTWdDcElLT1pUSnpLdG1WV1luSWEweE1iWlU2elFuKytWbWV5YkhudGZ0M0pQNkJtL1hBTmphTW93V1phWFU0K0Z0WmlmZnlTdmc3VFVFZz09",
  AccessKey: "u$r1b8d50f29ae9e60ce22e816847cdc424",
  SecretKey: "19a8768aea3261151df37ce0b55cd4d49d15b5a2",
  portalBaseURL: "https://portalapi-in21.leadsquared.com/api/Form",
  lsqFormBaseURL: "https://in21.leadsquared.com",
  lsqFormURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=64.0.3.2",
  lsqFormId: "1e7a6532-8ad5-11eb-b68d-02506fc2b3ce",

  // lsqFormProcessId: "d9471510-80af-11eb-a53a-0aa4c559f4ca",

  lsqRegistrationFormId: "92a45e14-888e-11eb-b68d-02506fc2b3ce",

  lsqRegistrationFormBaseURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=64.0.3.1",
};

export default config;

const API_Payload = {
  Columns: {
    Include_CSV:
    "ProspectID, FirstName ,LastName, EmailAddress,  ProspectStage,Phone,CreatedOn,mx_ReferralleadId,mx_ReferralID,mx_Lead_Type,mx_Season,mx_course_campus_name,mx_course_name,mx_course_campus_college_name,LeadLastModifiedOn"
  },
  Sorting: {
    ColumnName: "CreatedOn",
    Direction: "1",
  },
  TableData: [
    {
      label: "Full name",
      field: "mx_Fullname",
      sort: "asc",
      width: 160,
    },
    {
      label: "Email Address",
      field: "EmailAddress",
      sort: "asc",
      width: 200,
    },
    {
      label: "Mobile Number",
      field: "Phone",
      sort: "asc",
      width: 170,
    },
    
    {
      label: "Season",
      field: "Season",
      sort: "asc",
      width: 150,
    },
    {
      label: "Campus Name",
      field: "Branch",
      sort: "asc",
      width: 150,
    },
    {
      label: "Course Name",
      field: "Course",
      sort: "asc",
      width: 150,
    },
    {
      label: "College Name",
      field: "Campus",
      sort: "asc",
      width: 150,
    },
    {
      label: "Lead Status",
      field: "ProspectStage",
      sort: "asc",
      width: 120,
    },
    {
      label: "Created On",
      field: "CreatedOn",
      sort: "asc",
      width: 120,
    },
    {
      label: "Last Updated",
      field: "LeadLastModifiedOn",
      sort: "asc",
      width: 130,
    },
  ],
};

export default API_Payload;

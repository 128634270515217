import React from "react";
import $ from "jquery";
import utility from "../api-caller/utility";
import { withRouter } from "react-router-dom";
import "./leadImportForm.css";
import "./leadform.css";
import "./style.css";

import loading from "../../images/loading.gif";
import config from "../config";
/* global lsq_setupForm */
class LeadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leadId: null,
      loading: true,
    };

    var leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
      : this.props.history.push("/");
    console.log("in lead form ,", leadId);
    // if session expire redired user to login page
    if (!leadId) {
      // console.log('auth session already expired ');
      this.props.history.push("/");
    } else {
      // console.log('sd', leadId);
      this.state.leadId = leadId;
    }
    this.showLoading = this.showLoading.bind(this);
    // console.log('Render component created');
    this.loadOverrideCSS = this.loadOverrideCSS.bind(this);
    this.onLSQFormSubmissionSuccess = this.onLSQFormSubmissionSuccess.bind(
      this
    );
  }

  loadOverrideCSS() {
    // var cssFile = document.createElement('link');
    // cssFile.setAttribute('rel', 'stylesheet');
    // cssFile.setAttribute('type', 'text/css');
    // cssFile.setAttribute('href', '/lsq-form-override.css');
    // document.getElementsByTagName('head')[0].appendChild(cssFile);
    let leadSource = this.lsqFormContainer.querySelector("[id='1e7a6532-8ad5-11eb-b68d-02506fc2b3ce__tab1__section1__mx_Lead_consultant_new__Lead__0']");
      leadSource.value = localStorage.getItem("userNameValue");
    $("head").append(
      // '<link rel="stylesheet" type="text/css" href="/lsq-form-override.css">'
      '<link rel="stylesheet" type="text/css" href="/lsq-form-override.css">'
    );
    var date = new Date().toLocaleString();
    // $('input[data-schemaname="mx_Custom_2"]').val(date);
    console.log("this.state.leadId", this.state.leadId);
    $(".btn-primary btn lsq-form-action-btn").innerHTML = "Add New Lead";
    $('input[data-id="save"]').textContent = "new";
    $('input[data-schemaname="mx_ReferralleadId"]').val(this.state.leadId);

    console.log("$$==", $('input[data-schemaname="mx_ReferralleadId"]').val());

    window
      .$(
        // "#32304c80-0cf9-11ea-b5c2-0a2d2323ff7a__tab1__section1__mx_Custom_1mx_CustomObject_1__Activity__153"
        "#1e7a6532-8ad5-11eb-b68d-02506fc2b3ce__tab1__section1__mx_Custom_1mx_CustomObject_1__Activity__251"
      )
      .leadcombobox("setValueById", this.state.leadId);
    this.setState({ loading: false });
  }

  componentDidMount() {
    //authentication

    // console.log('leadid', this.state.leadId);

    $.getScript(config.lsqFormURL)
      .done(function (data) {
        lsq_setupForm({
          id: config.lsqFormId,
        });
      })
      .fail(function (error) {});

    this.lsqFormContainer.addEventListener(
      "lsqformloadcomplete",
      this.loadOverrideCSS
    );
    this.lsqFormContainer.addEventListener(
      "lsqformsubmissionsuccessateachstep",
      this.onLSQFormSubmissionSuccessAtEachStep
    );
    this.lsqFormContainer.addEventListener(
      "lsqformsubmissionsuccess",
      this.onLSQFormSubmissionSuccess
    );
    this.lsqFormContainer.addEventListener(
      "LSQFormLoadError",
      this.onLSQFormLoadError
    );
  }

  onLSQFormSubmissionSuccess() {
    console.log("Form Submitted Successfully");
    console.log(this.props);
    // window.location.reload(true);
    this.props.history.push("/Dashboard");
    window.location.reload(true);
    // console.log('lead created');
  }
  onLSQFormSubmissionSuccessAtEachStep(e) {}
  onLSQFormLoadError(e) {
    //Expire cookie
    // utilityFunction.ExpireAuthSessionInfoCookie();
    //log out
    //window.location.href = "/";
  }

  showLoading(show) {
    if (show)
      return (
        <div className="loader-container">
          <img src={loading} className="loader" alt="LSQEstates" />
        </div>
      );
  }
  render() {
    // console.log(this.props);
    var actualAuthKey =
      "UlBjYU5NSUhUS0ZhYnViUkhvU2tSbm00WkNRejgwVWhCUmN3Z3JxQ1pEU0g1dEdYUVpaQ0lZK3FZZnhQc3diSUNaQUV1N0NRSWdLdGIxSUZuWGxXWXR3Q0dLeW80T0czcUkwRk5sVExidTJXYXR5UytqOW82RWhkV2liVVYveGpkYjFMT2oxTWdDcElLT1pUSnpLdG1WV1luSWEweE1iWlU2elFuKytWbWV5YkhudGZ0M0pQNkJtL1hBTmphTW93V1phWFU0K0Z0WmlmZnlTdmc3VFVFZz09";

    return (
      <div className="container">
        <div id="render-form" style={{ backgroundColor: "white" }}>
          <div
            className=" d-flex justify-content-between"
            style={{ padding: "23px", backgroundColor: "white" }}
          >
            <div>Add New Lead</div>
            <i
              onClick={this.props.props.hideForm}
              className="fa fa-times float-right "
              style={{ fontSize: "24px", color: "#637381", cursor: "pointer" }}
              aria-hidden="true"
            ></i>
          </div>
          <div className="hr"></div>
          {/* render form (LSQ Form) */}
          <div
            id="lsq-form-modal"
            ref={(elem) => (this.lsqFormContainer = elem)}
            data-form-id={config.lsqFormId}
            className=" modal-v4 fullscreen external lsq-external-form-container p-0 pt-0"
          >
            {this.showLoading(this.state.loading)}
            <div className="lsq-form-container-wrapper   pt-0  "></div>
            <div className="lsq-form-hidden-fields">
              <input
                id="lsq-authKey"
                name="lsq-authKey"
                type="hidden"
                value={config.AuthKey}
              />
              <input
                id="lsq-api-service-url"
                name="lsq-api-service-url"
                type="hidden"
                value="https://portalapi-in21.leadsquared.com/api/Form"
              />
              <input
                id="lsq-app-url"
                name="lsq-app-url"
                type="hidden"
                value="https://in21.leadsquared.com"
              />
              <input
                id="lsq-form-id-hidden"
                name="lsq-form-id-hidden"
                type="hidden"
                value={config.lsqFormId}
              />
            </div>
          </div>
          {/* LSQ Form End */}
        </div>
      </div>
    );
  }
}
export default withRouter(LeadForm);

import Constants from "../constants/index";
import config from "../config/index";
import utilityFunction from "./utility";
import { ErrorAlert } from "../alert/Alert";
import "bootstrap";

function fetchLeadByRefereeLeadId(leadId) {
  var data = {
    Parameter: {
      LookupName: "mx_ReferrerLeadID",
      LookupValue: leadId,
      SqlOperator: "=",
    },
    Columns: {
      Include_CSV:
        "ProspectID, FirstName, LastName, EmailAddress, mx_Project_Name, mx_House_Type, ProspectStage,Mobile",
    },
    Sorting: {
      ColumnName: "CreatedOn",
      Direction: "1",
    },
    Paging: {
      PageIndex: 1,
      PageSize: 100,
    },
  };
  fetch(
    Constants.LSQAPI.baseURL +
      Constants.LSQAPI.endPoints.GetLeadByFieldDetail +
      "?accessKey=" +
      config.AccessKey +
      "&secretKey=" +
      config.SecretKey,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((Response) => Response.json())
    .then((json) => {
      console.log("get lead details ", json);
      return json;
    });
}

function Register(data, form_props, loginData) {
  fetch(Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.Register, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: config.AuthKey,
    },
  })
    .then((Response) => Response.json())
    .then((json) => {
      console.log(json);
      if (json.hasOwnProperty("Status") && json.Status === "Error") {
        let er = json.ExceptionMessage;
        form_props.changeState({
          formError: ErrorAlert(er, "danger"),
        });
      } else if (json.hasOwnProperty("LeadId")) {
        localStorage.clear();
        console.log("data", data);
        localStorage.setItem("username", loginData);
        var authSessionInfo = {
          isAuthenticated: true,
          leadId: json.SiginResponse.LeadId,
          authKey: json.SiginResponse.AuthKey,
        };
        console.log("authSessionInfo ", authSessionInfo);
        utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);
        // console.log('cookie', document.cookie);
        form_props.changeState({
          formError: ErrorAlert(
            "Successfully Registered . Redirecting to Dashboard.",
            "success"
          ),
        });
        form_props.props.history.push({
          pathname: "/Dashboard",
          search: "",
          state: { detail: json },
        });

        form_props.resetForm();
      } else {
        console.log("Exception :", "Network disconnected");
        form_props.changeState({
          formError: ErrorAlert("Some Exception Occured", "danger"),
        });
      }
    })
    .catch((er) => {
      console.log("Exception :", er);
      form_props.changeState({
        formError: ErrorAlert("Some Exception Occured", "danger"),
      });
    });
}
function Login(data, form_props) {
  console.log("inside login", data, form_props);
  // alert("inside login-=", form_props);
  // fetch(Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.Signin, {
  //   method: "POST",
  //   body: JSON.stringify(data),
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: config.AuthKey,
  //   },
  // })
  //   .then((Response) => Response.json())
  //   .then((json) => {
  //     console.log(json);
  //     if (json.hasOwnProperty("Status") && json.Status === "Error") {
  //       let er = json.ExceptionMessage;
  //       form_props.changeState({
  //         formError: ErrorAlert(er, "danger"),
  //       });
  //     } else if (json.hasOwnProperty("LeadId")) {
  //       localStorage.clear();
  //       console.log("data", data);
  //       localStorage.setItem("username", data);
  //       var authSessionInfo = {
  //         isAuthenticated: true,
  //         leadId: json.LeadId,
  //         authKey: json.AuthKey,
  //       };
  //       console.log("authSessionInfo ", authSessionInfo);
  //       utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);
  //       // console.log('cookie', document.cookie);

  //       form_props.changeState({
  //         formError: ErrorAlert(
  //           "Successfully Registered . Redirecting to Dashboard.",
  //           "success"
  //         ),
  //       });
  //       form_props.resetForm();

  //       form_props.props.history.push({
  //         pathname: "/Dashboard",
  //         search: "", // search: '?query=abc',
  //         state: { detail: json },
  //       });
  //     } else {
  //       console.log("Exception :", "Network disconnected");
  //       form_props.changeState({
  //         formError: ErrorAlert("Some Exception Occured", "danger"),
  //       });
  //     }
  //   })
  //   .catch((er) => {
  //     console.log("Exception :", er);
  //     form_props.changeState({
  //       formError: ErrorAlert("Some Exception Occured", "danger"),
  //     });
  //   });

  fetch(Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.Signin, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: config.AuthKey,
    },
  })
    .then((Response) => Response.json())

    .then(function (response) {
      localStorage.clear();
      console.log("data", response);
      localStorage.setItem("username", response.data);

      var authSessionInfo = {
        isAuthenticated: true,
        leadId: response.LeadId,
        authKey: response.AuthKey,
      };
      console.log("authSessionInfo ", authSessionInfo);
      utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);

      // utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);
      // that.props.updateLoginInfo(authSessionInfo);
      // that.props.updateLeadDetails(completeLeadDetails);

      // for after signup success message
      console.log("successfully  login");
      localStorage.setItem("isSuccess", true);
      // alert("success login");
      form_props.props.history.push("/Dashboard");
    })
    .catch(function (error) {
      console.log(error, "error");

      alert("error=2", error);
    });
}

function ResetPassword(payload, props) {
  console.log("Reset password password payload props", payload, props);
  fetch(
    Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.ResetPassword,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: config.AuthKey,
        Referer: "https://leapfinance-8uyjayag.lsqportal-test.com",
      },
    }
  )
    .then((json) => json.json())
    .then(function (json) {
      console.log("reset password response", json);
      if (json.hasOwnProperty("Status") && json.Status === "Error") {
        props.changeState({
          formError: ErrorAlert(json.ExceptionMessage, "danger"),
        });
      } else {
        props.changeState({
          formError: ErrorAlert(
            "Your password has been reset successfully!",
            "success"
          ),
        });
        window.setTimeout(function () {
          props.props.history.push("/");
        }, 1500);
      }
    })
    .catch(function (error) {
      props.changeState({
        uError: ErrorAlert(
          "Sorry for inconvinence .Please try again",
          "danger"
        ),
      });
      console.log("Exception :", error);
    });
}
function ForgotPasswordAPI(payload, form_props) {
  console.log("Forgot password payload props", payload, form_props);
  fetch(
    Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.ForgotPassword,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: config.AuthKey,
        Referer: "https://leapfinance-8uyjayag.lsqportal-test.com",
      },
      method: "POST",
      body: JSON.stringify(payload),
    }
  )
    .then((response) => response.json())
    .then(function (response) {
      console.log("response", response);
      if (response.Status === "Error") {
        form_props.changeState({
          formError: ErrorAlert(response.ExceptionMessage, "danger"),
        });
      } else {
        form_props.changeState({
          formError: ErrorAlert(
            "A Password reset link has been sent to " + payload.EmailAddress,
            "success"
          ),
          isButton: true
        });
      }
      form_props.resetForm();
    })
    .catch(function (error) {
      console.log("Exception :", error);
      form_props.changeState({
        formError: ErrorAlert(error, "danger"),
        isButton: false
      });
    });
}

export {
  Register,
  Login,
  ForgotPasswordAPI,
  fetchLeadByRefereeLeadId,
  ResetPassword,
};

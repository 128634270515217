import Config from '../config/index';
import constants from '../constants/index';
import utilityFunction from '../api-caller/utility';

function GetUsername(leadId, updateStateRef, props) {
  fetch(
    constants.LSQAPI.baseURL +
    constants.LSQAPI.endPoints.GetLeadsById +
    '?accessKey=' +
    Config.AccessKey +
    '&secretKey=' +
    Config.SecretKey +
    '&id=' +
    leadId,
    { method: 'GET' }
  )
    .then((response) => response.json())
    .then((json) => {
      var fistname = json[0].FirstName ? json[0].FirstName : '';
      var lastname = json[0].LastName ? json[0].LastName : '';

      updateStateRef.changeState({
        username: fistname + ' ' + lastname
      });
      // console.log('usrname', json);
      localStorage.setItem("userNameValue",fistname + ' ' + lastname)
    })
    .catch(function (error) {
      console.log('Exception : ', error);
      utilityFunction.ExpireAuthSessionInfoCookie();
      props.history.push('/');
    });
}
export default GetUsername;
